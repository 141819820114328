import React, { Component } from 'react'
import { Navbar, Nav, Container, Figure, Form } from 'react-bootstrap'
import logo from '../../assets/Logo_White.png'
import './Header.css'
import AvatarMenu from '../avatar_menu/AvatarMenu'

class Header extends Component {
	constructor(prop) {
		super(prop)
		this.state = {
			isAuthenticated: this.props.userInfo === undefined ? false : true,
		}
		this.onLogout = this.onLogout.bind(this)
	}

	// Logout user
	onLogout() {
		this.setState({
			isAuthenticated: false,
		})
	}

	render() {
		if (this.state.isAuthenticated === false) {
			var guestLinks = <Nav.Link href="/login">Log In</Nav.Link>
		} else {
			var authLinksV2 = (
				<AvatarMenu
					userinfov2={this.props.userInfo}
					refreshhandler={this.onLogout}
				/>
			)
		}

		return (
			<header>
				<Navbar
					bg="primary"
					variant="dark"
					expand="lg"
					collapseOnSelect
					fixed="top"
				>
					<Container>
						<Navbar.Brand href="/" className="logo">
							<Figure.Image
								width={200}
								alt="BillyCam"
								src={logo}
							/>
						</Navbar.Brand>
						<Navbar.Toggle aria-controls="justify-content-end basic-navbar-nav" />
						<Navbar.Collapse
							id="basic-navbar-nav"
							className="justify-content-end"
						>
							<Nav>
								<Nav.Link href="/">Home</Nav.Link>
								<Nav.Link href="/livestreams">
									Livestreams
								</Nav.Link>
								<Nav.Link href="/recordings">
									Recordings
								</Nav.Link>
								<Nav.Link href="/about">About Us</Nav.Link>
								<Nav.Link href="/contact">Contact Us</Nav.Link>
								{this.state.isAuthenticated ? null : guestLinks}
							</Nav>
							<Form>
								{this.state.isAuthenticated
									? authLinksV2
									: null}
							</Form>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</header>
		)
	}
}

export default Header
