import React from 'react'
import { Button, Card, Container, Row, Col } from 'react-bootstrap'

const InvalidCode = () => {
	return (
		<Card className="invalid-card">
			<Card.Body>
				<Card.Title>
					<h1>Invalid Code</h1>
				</Card.Title>
				<h3>
					Code has either expired or was never created.
					<br />
					Please go back to either Forgot Password or Verify Account
					to resend code.
				</h3>
				<Container fluid>
					<Row>
						<Col>
							<Button
								className="invalid-button"
								onClick={() => {
									window.location.replace('/forgot')
								}}
							>
								Forgot Password
							</Button>
						</Col>
						<Col>
							<Button
								className="invalid-button"
								onClick={() => {
									window.location.replace('/verify')
								}}
							>
								Verify Account
							</Button>
						</Col>
					</Row>
				</Container>
			</Card.Body>
		</Card>
	)
}

export default InvalidCode
