import React from 'react'
import {
	Container,
	Row,
	Col,
	Image /*, Button, Tabs, Tab */,
} from 'react-bootstrap' /* To be added back in once description content is decided */
import BillyCam from '../../assets/BillyCam.png'
import './about.css'

// Scrolls down the page to specific div
// const scrollDown = () => {
//   document.getElementById("description-tags-div").scrollIntoView();
// }

// Size for the Tab Icons
// const iconSize = "6x";

/**
 * Tab Icons for about page tabs
 * Requires a fontAwesomeIcon, text string, and an iconsize eg. "6x"
 */
// const clockTab = <TabIcon className="aboutPageTabs" icon={faClock} text="5 Minute Setup" iconsize={iconSize}/>;
// const filmTab = <TabIcon className="aboutPageTabs" icon={faFilm} text="High Quality Streaming" iconsize={iconSize}/>;
// const usersTab = <TabIcon className="aboutPageTabs" icon={faUsers} text="Unlimited Viewers" iconsize={iconSize}/>;
// const downloadTab = <TabIcon className="aboutPageTabs" icon={faDownload} text="Save Your Game Footage" iconsize={iconSize}/>;
// const cameraTab = <TabIcon className="aboutPageTabs" icon={faCamera} text="Top-Down View" iconsize={iconSize}/>;

// Text strings for about page tabs
// const setupDesc = 'Curabitur dignissim aliquam massa a cursus. In a nisl magna. Fusce rutrum convallis sem eget rhoncus. In posuere mi felis, nec finibus tortor sagittis ut. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; In hac habitasse platea dictumst. Suspendisse potenti.'

// const streamingDesc = 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean viverra iaculis elementum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at elit est. Phasellus eu auctor est. Mauris sagittis eget ligula sed aliquet. Vivamus id lacus odio. Vestibulum blandit urna quis lorem mattis egestas nec ac ante. Ut a justo nec tellus sodales vehicula.'

// const viewersDesc = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed qui libero, soluta vero accusantium odio aliquam voluptatum distinctio odit, in esse magnam sapiente praesentium quod rem quaerat hic quasi est!'

// const downloadDesc = 'Sed malesuada interdum vestibulum. Mauris mi dui, lobortis eget tincidunt non, dignissim sit amet lectus. Mauris pellentesque ac sem vitae porta. Morbi pellentesque consequat mauris, et malesuada turpis imperdiet eu. Nam scelerisque tellus mi, quis pretium mi consectetur eu. Nam semper vitae metus imperdiet placerat.'

// const cameraDesc = 'Proin bibendum lobortis justo ut cursus. Ut bibendum laoreet ipsum, nec fringilla erat facilisis ut. Ut venenatis mauris et mollis efficitur. Vestibulum rutrum euismod dui ut finibus. Curabitur sit amet purus quis dui faucibus tincidunt vel eu nunc. Mauris vel dignissim felis. Suspendisse in purus id lacus pellentesque auctor.'

const AboutPage = () => {
	return (
		<Container>
			<Container className="top">
				<Row>
					<Col>
						<h1 className="titleH1">ABOUT BILLYCAM</h1>
						<p>
							Changing the way players see the game of pool. The
							BillyCam allows you to: <br />
							<br />
						</p>
						<ol>
							<li>
								View the action live to screen <br />
								<br />
								On location with a much better viewing angle{' '}
								<br />
								<br />
								Or remotely from anywhere that there’s an
								internet connection <br />
								<br />
							</li>
							<li>
								Playback recordings of your games <br />
								<br />
								Coach your teammates <br />
								<br />
								Analyze your own game <br />
								<br />
								Settle disputes over rule infractions quickly,
								easily and fairly <br />
								<br />
							</li>
							<li>
								Download recordings and share them by text,
								email or even social media.
							</li>
						</ol>
					</Col>
					<Col>
						<Image
							className="billyimage"
							src={BillyCam}
							alt="BillyCam Product Image"
						></Image>
					</Col>
				</Row>
				{/* To be added back in once description content is decided */}
				{/* <Button variant="primary" onClick={scrollDown}>Learn More</Button> */}
			</Container>

			{/* To be added back in once description content is decided */}
			{/* <Container id="description-tags-div" className="middle">
        <h1 className="uniqueH1">WHAT MAKES BILLYCAM UNIQUE?</h1>
        <Tabs justify defaultActiveKey="setup" id="description-tags" className="justify-content-center">
          <Tab className="aboutPageTabs" eventKey="setup" title={clockTab}>
            {setupDesc}
          </Tab>
          <Tab className="aboutPageTabs" eventKey="streaming" title={filmTab}>
            {streamingDesc}
          </Tab>
          <Tab className="aboutPageTabs" eventKey="viewers" title={usersTab}>
            {viewersDesc}
          </Tab>
          <Tab className="aboutPageTabs" eventKey="download" title={downloadTab}>
            {downloadDesc}
          </Tab>
          <Tab className="aboutPageTabs" eventKey="camera" title={cameraTab}>
            {cameraDesc}
          </Tab>
        </Tabs>
      </Container> */}
		</Container>
	)
}

export default AboutPage
