import React, { useState } from 'react'
import { Form, Button, Card, Container } from 'react-bootstrap'
import EmailSending from '../../classes/emailSending'
import axios from 'axios'
import { API_PasswordValidate } from '../../api/API_EmailPass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const eye = <FontAwesomeIcon icon={faEye} size="2x" />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} size="2x" />

const SpecialPassError = (
	<span>
		Password must be:
		<ul>
			<li>At least 6 characters</li>
			<li>At least one number,</li>
			<li>At least one lowercase,</li>
			<li>At least one uppercase letter,</li>
			<li>At least one special character(!@#$%^&*) </li>
		</ul>
	</span>
)

const sendEmail = new EmailSending()

const ResetForm = (props) => {
	const [newPassword, setNewPassword] = useState('')
	const [confirmedPassword, setConfirmedPassword] = useState('')
	const [error, setError] = useState('')
	const [isPasswordVisible, setIsPasswordVisible] = useState('')
	const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState('')
	const email = props.email
	const code = props.code

	const eyeShow = (
		<i className="eyeIcon" onClick={() => setIsPasswordVisible(false)}>
			{eye}
		</i>
	)

	const eyeSlashShow = (
		<i className="eyeIcon" onClick={() => setIsPasswordVisible(true)}>
			{eyeSlash}
		</i>
	)

	const eyeShowConfirm = (
		<i
			className="eyeIcon"
			onClick={() => setIsConfirmPasswordVisible(false)}
		>
			{eye}
		</i>
	)

	const eyeSlashShowConfirm = (
		<i
			className="eyeIcon"
			onClick={() => setIsConfirmPasswordVisible(true)}
		>
			{eyeSlash}
		</i>
	)

	const onSubmit = (e) => {
		e.preventDefault()

		setError('')

		if (
			!API_PasswordValidate(newPassword) ||
			!API_PasswordValidate(confirmedPassword)
		) {
			setError(SpecialPassError)
		}

		if (newPassword !== confirmedPassword) {
			setError('Passwords do not match')
		}

		if (newPassword === '' || confirmedPassword === '') {
			setError('All fields are required')
		}

		if (newPassword === confirmedPassword && error === '') {
			try {
				// Sends new reset password email
				axios
					.post(
						`${process.env.REACT_APP_WEB_API_HOST}/user/reset-password`,
						{
							email: email,
							newPassword: newPassword,
							confirmedPassword: confirmedPassword,
						}
					)
					.then(async (res) => {
						try {
							// Deletes code so it can't be used again
							await axios
								.post(
									`${process.env.REACT_APP_WEB_API_HOST}/codes/delete-code`,
									{
										code: code,
									}
								)
								.then((res) => {
									console.log(res.data)
								})

							await sendEmail.changesOccuredEmail(
								email,
								'Password'
							)
							window.location.replace('/login')
						} catch (error) {
							console.log(error)
						}
					})
			} catch (error) {
				setError(error)
			}
		}
	}

	return (
		<Card className="reset-card">
			<Card.Body>
				<Card.Title>
					<h1>Password Reset</h1>
				</Card.Title>
				<Form onSubmit={onSubmit}>
					<Form.Text className="text-muted">
						For Email: {email}
					</Form.Text>
					<Form.Group className="mb-3" controlId="resetPasswordForm">
						<Container>
							<Form.Label>New Password</Form.Label>
							{isPasswordVisible ? eyeShow : eyeSlashShow}
							<Form.Control
								type={isPasswordVisible ? 'text' : 'password'}
								onChange={(e) => {
									setNewPassword(e.target.value)
								}}
								placeholder=""
							/>
						</Container>
						<Container>
							<Form.Label>Confirm Password</Form.Label>
							{isConfirmPasswordVisible
								? eyeShowConfirm
								: eyeSlashShowConfirm}
							<Form.Control
								type={
									isConfirmPasswordVisible
										? 'text'
										: 'password'
								}
								onChange={(e) => {
									setConfirmedPassword(e.target.value)
								}}
								placeholder=""
							/>
						</Container>
						<Form.Text className="text-danger">{error}</Form.Text>
					</Form.Group>
					<Button variant="primary" type="submit">
						Reset Password
					</Button>
				</Form>
			</Card.Body>
		</Card>
	)
}

export default ResetForm
