import React from 'react'
import { Container, Image, Row, Col } from 'react-bootstrap'
import Image404 from '../../assets/4043D.png'
import './notFound.css'

const NotFoundPage = () => {
	return (
		<Container className="wrapper">
			<Row>
				<Col>
					<Image src={Image404} id="Image404" />
				</Col>
				<Col className="text404">
					<h1>Oops... Looks like you Scratched</h1>
					<h2>Good thing it was only a practice shot.</h2>
					<h2>
						Take the shot again by starting back at{' '}
						<a href="/">home</a>.
					</h2>
				</Col>
			</Row>
		</Container>
	)
}

export default NotFoundPage
