import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { Tabs, Tab, Button, Form } from 'react-bootstrap'
import FormGeneralInfoV1 from './FormGeneralInfoV1'
import FormSocials from './FormSocials'
import FormCameras from './FormCameras'
import CameraPopup from './CameraPopup'
import CameraPopupAdd from './CameraPopupAdd'
import CameraPopupDel from './CameraPopupDel'
import { ColoredLine, WeekdayContent } from './UIElements'
import { AuthContext } from '../../shared/context/auth-context'

import './VenueSettings.css'

const __ROLES = {
	GeneralUser: 1,
	Paying: 2,
	VenueOwner: 3,
	Admin: 9,
	Super: 10,
}

/**
 * Venue settings page.
 *
 * @version 1.0.0
 * @author Nemo(xwang59@academic.rrc.ca)
 */
class VenueSettings extends Component {
	// make sure data retrieving only once.
	dataRetrived = false
	venueId

	constructor(props) {
		super(props)
		this.state = {
			redirect: '/login/',
			redirectHome: '/',
			// for general information processing
			isVenueReadOnly: true,
			isDescribeReadOnly: true,
			logo: 'default.png',
			venue: '',
			desc: '',

			// for address information processing
			isProvinceReadOnly: true,
			isCityReadOnly: true,
			isAddressReadOnly: true,
			isPostReadOnly: true,
			province: '',
			city: '',
			add: '',
			post: '',

			// for social links
			isWebsiteReadOnly: true,
			isGoogleReadOnly: true,
			isAppleReadOnly: true,
			isFacebookReadOnly: true,
			isInstagramReadOnly: true,
			isTwitterReadOnly: true,
			website: '',
			google: '',
			apple: '',
			facebook: '',
			instagram: '',
			twitter: '',

			// for hours of operation processing
			mon: ['11:00', '11:00'],
			tue: ['11:00', '11:00'],
			wed: ['11:00', '11:00'],
			thu: ['11:00', '11:00'],
			fri: ['11:00', '11:00'],
			sat: ['11:00', '11:00'],
			sun: ['11:00', '11:00'],

			// for all the camera display
			cameras: [],
			isCameraPopShow: false,
			isCameraPopAddShow: false,
			isCameraPopDelShow: false,

			// for camera items processing.
			camTNum: 0,
			camKey: '',
			camId: '',
			_camId: null,
			camDesc: '',
			camHeader: '',

			// for camera adding funcion
			camTNumAdd: 0,
			camKeyAdd: '',
			camIdAdd: '',
			camDescAdd: '',
			camHeaderAdd: '',
		}
		this.venueId = null
	}

	// venue name edit
	onVenueChangeHandler = (e) => {
		this.setState({ venue: e.target.value })
	}

	// venue description double clicked
	onDescDClickHandler = () => {
		this.setState({ isDescribeReadOnly: !this.state.isDescribeReadOnly })
	}

	// venue description edit
	onDescChangeHandler = (e) => {
		this.setState({ desc: e.target.value })
	}

	// province double clicked
	onProvinceDClickHandler = () => {
		this.setState({ isProvinceReadOnly: !this.state.isProvinceReadOnly })
	}

	// province edit
	onProvinceChangeHandler = (e) => {
		this.setState({ province: e.target.value })
	}

	// city double clicked
	onCityDClickHandler = () => {
		this.setState({ isCityReadOnly: !this.state.isCityReadOnly })
	}

	// city edit
	onCityChangeHandler = (e) => {
		this.setState({ city: e.target.value })
	}

	// address double clicked
	onAddressDClickHandler = () => {
		this.setState({ isAddressReadOnly: !this.state.isAddressReadOnly })
	}

	// address edit
	onAddChangeHandler = (e) => {
		this.setState({ add: e.target.value })
	}

	// post double clicked
	onPostDClickHandler = () => {
		this.setState({ isPostReadOnly: !this.state.isPostReadOnly })
	}

	// post edit
	onPostChangeHandler = (e) => {
		this.setState({ post: e.target.value })
	}

	// website double clicked
	onWebsiteDClickHandler = () => {
		this.setState({ isWebsiteReadOnly: !this.state.isWebsiteReadOnly })
	}

	// website edit
	onWebsiteChangeHandler = (e) => {
		this.setState({ website: e.target.value })
	}

	// apple map double clicked
	onAppleDClickHandler = () => {
		this.setState({ isAppleReadOnly: !this.state.isAppleReadOnly })
	}

	// apple map edit
	onAppleChangeHandler = (e) => {
		this.setState({ apple: e.target.value })
	}

	// google map double clicked
	onGoogleDClickHandler = () => {
		this.setState({ isGoogleReadOnly: !this.state.isGoogleReadOnly })
	}

	// apple map edit
	onGoogleChangeHandler = (e) => {
		this.setState({ google: e.target.value })
	}

	// facebook double clicked
	onFacebookDClickHandler = () => {
		this.setState({ isFacebookReadOnly: !this.state.isFacebookReadOnly })
	}

	// facebook edit
	onFacebookChangeHandler = (e) => {
		this.setState({ facebook: e.target.value })
	}

	// instagram double clicked
	onInstagramDClickHandler = () => {
		this.setState({ isInstagramReadOnly: !this.state.isInstagramReadOnly })
	}

	// instagram edit
	onInstagramChangeHandler = (e) => {
		this.setState({ instagram: e.target.value })
	}

	// twitter double clicked
	onTwitterDClickHandler = () => {
		this.setState({ isTwitterReadOnly: !this.state.isTwitterReadOnly })
	}

	// twitter edit
	onTwitterChangeHandler = (e) => {
		this.setState({ twitter: e.target.value })
	}

	// venue information saving
	onVenueSaveHandler = (e) => {
		// stop page refreshing
		e.preventDefault()

		axios
			.post(
				`${process.env.REACT_APP_WEB_API_HOST}/venues-padding/update-general/`,
				{
					id: this.venueId,
					venue: this.state.venue,
					desc: this.state.desc,
				}
			)
			.then((res) => {
				if (res.data.status) {
					console.log(res.data.result)
				}
			})
			.catch((error) => {
				console.log('There was an error!', error)
			})
	}

	// venue address information saving
	onAddSaveHandler = (e) => {
		// stop page refreshing
		e.preventDefault()

		axios
			.post(
				`${process.env.REACT_APP_WEB_API_HOST}/venues-padding/update-address/`,
				{
					id: this.venueId,
					province: this.state.province,
					city: this.state.city,
					address: this.state.add,
					post: this.state.post,
				}
			)
			.then((res) => {
				if (res.data.status) {
					console.log(res.data.result)
				}
			})
			.catch((error) => {
				console.log('There was an error!', error)
			})
	}

	// social links saving
	onSocialsSaveHandler = (e) => {
		// stop page refreshing
		e.preventDefault()

		axios
			.post(
				`${process.env.REACT_APP_WEB_API_HOST}/venues-padding/update-socials/`,
				{
					id: this.venueId,
					website: this.state.website,
					apple: this.state.apple,
					google: this.state.google,
					facebook: this.state.facebook,
					instagram: this.state.instagram,
					twitter: this.state.twitter,
				}
			)
			.then((res) => {
				if (res.data.status) {
					console.log(res.data.result)
				}
			})
			.catch((error) => {
				console.log('There was an error!', error)
			})
	}

	// hours of operation saving
	onHoursSaveHandler = (e) => {
		// stop page refreshing
		e.preventDefault()

		axios
			.post(
				`${process.env.REACT_APP_WEB_API_HOST}/venues-padding/update-hours/`,
				{
					id: this.venueId,
					mon: this.state.mon,
					tue: this.state.tue,
					wed: this.state.wed,
					thu: this.state.thu,
					fri: this.state.fri,
					sat: this.state.sat,
					sun: this.state.sun,
				}
			)
			.then((res) => {
				if (res.data.status) {
					console.log(res.data.result)
				}
			})
			.catch((error) => {
				console.log('There was an error!', error)
			})
	}

	// preparing data for camera popup display
	onFormCameraItemClickHandler = (e) => {
		this.setState({
			isCameraPopShow: true,
			camTNum: e.ctable,
			camKey: e.ckey,
			camId: e.cid,
			_camId: e._cid,
			camDesc: e.cdesc,
			camHeader: e.header,
		})
	}

	// preparing data for camera deleting
	onFormCameraItemDelClickHandler = (e) => {
		this.setState({
			isCameraPopDelShow: true,
			camTNum: e.ctable,
			camKey: e.ckey,
			camId: e.cid,
			camDesc: e.cdesc,
			_camId: e._cid,
		})
	}

	// close camera popup
	handleCloseHandler = (e) => {
		this.setState({
			isCameraPopShow: false,
		})
	}

	// save edited camera
	handleEditSaveHandler = (e) => {
		// save current camera
		axios
			.post(
				`${process.env.REACT_APP_WEB_API_HOST}/venues-padding/edit-cam/`,
				{
					id: this.venueId,
					camkey: this.state.camKey,
					camid: this.state.camId,
					_camid: this.state._camId,
					camtbl: this.state.camTNum,
					camdesc: this.state.camDesc,
				}
			)
			.then((res) => {
				if (res.data.status) {
					// get updated cameras
					axios
						.get(
							`${process.env.REACT_APP_WEB_API_HOST}/venues-padding/cameras/${this.venueId}`
						)
						.then((res) => {
							this.setState({
								isCameraPopShow: false,
								cameras: res.data.cameras,
							})
						})
						.catch((error) => {
							console.log('There is an error!', error)
						})
				}
			})
			.catch((error) => {
				console.log('There was an error!', error)
			})
	}

	// adding new camera
	onFormCameraAddClickHandler = (e) => {
		this.setState({
			isCameraPopAddShow: true,
			// number of the next camera
			camHeaderAdd: e + 1,
		})
	}

	// close camera adding popup
	handleCloseAddHandler = (e) => {
		// reset the information for next camera adding
		this.setState({
			isCameraPopAddShow: false,
			camTNumAdd: 0,
			camKeyAdd: '',
			camIdAdd: '',
			camDescAdd: '',
		})
	}

	// close camera deleting popup
	handleCloseDelHandler = (e) => {
		this.setState({
			isCameraPopDelShow: false,
		})
	}

	// conform camera deleting
	handleOkDelHandler = (e) => {
		//  delete current camera
		axios
			.post(
				`${process.env.REACT_APP_WEB_API_HOST}/venues-padding/del-cam/`,
				{
					id: this.venueId,
					camkey: this.state.camKey,
					camid: this.state.camId,
					camtbl: this.state.camTNum,
					_camid: this.state._camId,
				}
			)
			.then((res) => {
				if (res.data.status) {
					// get updated cameras list
					axios
						.get(
							`${process.env.REACT_APP_WEB_API_HOST}/venues-padding/cameras/${this.venueId}`
						)
						.then((res) => {
							this.setState({
								cameras: res.data.cameras,
								isCameraPopDelShow: false,
							})
						})
						.catch((error) => {
							console.log('There is an error!', error)
						})
				}
			})
			.catch((error) => {
				console.log('There was an error!', error)
			})
	}

	// camera adding
	camAddSaveHandler = () => {
		// add current camera
		axios
			.post(
				`${process.env.REACT_APP_WEB_API_HOST}/venues-padding/add-cam/`,
				{
					id: this.venueId,
					camkey: this.state.camKeyAdd,
					camid: this.state.camIdAdd,
					camtbl: this.state.camTNumAdd,
					camdesc: this.state.camDescAdd,
				}
			)
			.then((res) => {
				if (res.data.status) {
					// get updated cameras list
					axios
						.get(
							`${process.env.REACT_APP_WEB_API_HOST}/venues-padding/cameras/${this.venueId}`
						)
						.then((res) => {
							this.setState({
								cameras: res.data.cameras,
								camTNumAdd: 0,
								camKeyAdd: '',
								camIdAdd: '',
								camDescAdd: '',
								camHeaderAdd: '',
							})
						})
						.catch((error) => {
							console.log('There is an error!', error)
						})
				}
			})
			.catch((error) => {
				console.log('There was an error!', error)
			})
		// refresh page
		this.setState({
			isCameraPopAddShow: false,
		})
	}

	// table number editting of camera
	onCamTNumChangeHandler = (e) => {
		this.setState({ camTNum: e.target.value })
	}

	// camera key editting
	onCamKeyChangeHandler = (e) => {
		this.setState({ camKey: e.target.value })
	}

	// camera id editting
	onCamIdChangeHandler = (e) => {
		this.setState({ camId: e.target.value })
	}

	// camera description editting
	onCamDescChangeHandler = (e) => {
		this.setState({ camDesc: e.target.value })
	}

	// camera table editting of camera adding
	onCamTNumAddChangeHandler = (e) => {
		this.setState({ camTNumAdd: e.target.value })
	}

	// camera key editting of camera adding
	onCamKeyAddChangeHandler = (e) => {
		this.setState({ camKeyAdd: e.target.value })
	}

	// camera id editting of camera adding
	onCamIdAddChangeHandler = (e) => {
		this.setState({ camIdAdd: e.target.value })
	}

	// camera description editting of camera adding
	onCamDescAddChangeHandler = (e) => {
		this.setState({ camDescAdd: e.target.value })
	}

	// get all the page display information after the page components loaded.
	componentDidUpdate() {
		let auth = this.context
		if (auth.isLoggedIn && !this.dataRetrived) {
			this.dataRetrived = true
			this.RetriveData(auth.userId)
		}
	}

	// retrive data from database
	RetriveData(userId) {
		axios
			.get(
				`${process.env.REACT_APP_WEB_API_HOST}/venues-padding/${userId}`
			)
			.then((res) => {
				this.venueId = res.data._id

				this.setState({
					// logo: res.data.logo,
					venue: res.data.name,
					desc: res.data.description,
					venueNum: res.data.venueNumber,
					province: res.data.province,
					city: res.data.city,
					add: res.data.address,
					post: res.data.postalCode,
					//
					website: res.data.socialLinks.website,
					google: res.data.socialLinks.googleMap,
					apple: res.data.socialLinks.appleMap,
					facebook: res.data.socialLinks.facebook,
					instagram: res.data.socialLinks.instagram,
					twitter: res.data.socialLinks.twitter,
					// format: ["10:00", "11:11"]
					mon: [res.data.hoursOfOp.mon[0], res.data.hoursOfOp.mon[1]],
					tue: [res.data.hoursOfOp.tue[0], res.data.hoursOfOp.tue[1]],
					wed: [res.data.hoursOfOp.wed[0], res.data.hoursOfOp.wed[1]],
					thu: [res.data.hoursOfOp.thu[0], res.data.hoursOfOp.thu[1]],
					fri: [res.data.hoursOfOp.fri[0], res.data.hoursOfOp.fri[1]],
					sat: [res.data.hoursOfOp.sat[0], res.data.hoursOfOp.sat[1]],
					sun: [res.data.hoursOfOp.sun[0], res.data.hoursOfOp.sun[1]],
					//
					cameras: res.data.camera,
				})

				this.setState({
					logo: res.data.logo,
				})
			})
			.catch((error) => {
				console.log('There is an error!', error)
			})
	}

	// get the user auth information
	componentDidMount() {
		// let auth = this.context;
		this.setState({
			redirect: this.state.redirect,
		})
	}

	// connect weekday with corresponding operation hours
	onChangeHandler = (e, weekday) => {
		let day = weekday.substr(0, 3).toLowerCase()
		this.setState({ [`${day}`]: e })
	}

	// weekday
	TableWeekday = (weekday, time) => {
		return (
			<Tab eventKey={weekday} title={weekday}>
				<WeekdayContent
					period={time}
					which={weekday}
					onChange={(e) => {
						this.onChangeHandler(e, weekday)
					}}
				/>
			</Tab>
		)
	}

	// entire form of weekdays
	// TODO: Extract to component file, pass in control fn's
	FormOperationHoursV1 = (data) => {
		return (
			<Form>
				<Form.Group className="mb-3">
					<Tabs defaultActiveKey="Monday" variant="tabs">
						{this.TableWeekday('Monday', data.mon)}
						{this.TableWeekday('Tuesday', data.tue)}
						{this.TableWeekday('Wednesday', data.wed)}
						{this.TableWeekday('Thursday', data.thu)}
						{this.TableWeekday('Friday', data.fri)}
						{this.TableWeekday('Saturday', data.sat)}
						{this.TableWeekday('Sunday', data.sun)}
					</Tabs>

					<div className="btn-align-right">
						<Button
							variant="warning"
							type="submit"
							onClick={this.onHoursSaveHandler}
						>
							Save
						</Button>
					</div>
				</Form.Group>
			</Form>
		)
	}

	redirectBug = 0
	render() {
		this.redirectBug++
		if (this.redirectBug >= 2) {
			if (!this.context.isLoggedIn) {
				return <Redirect to={this.state.redirect} />
			}
			if (
				this.context.isLoggedIn &&
				this.context.userRole !== __ROLES.VenueOwner
			) {
				return <Redirect to={this.state.redirectHome} />
			}
		}

		return (
			<div className="com-venue">
				<div className="div-block">
					<h2>Venue Settings</h2>
					<span className="venue-num-right">
						Venue #:{this.state.venueNum}
					</span>
				</div>
				<a href="/currentvideos">Current Recordings and Livestreams</a>
				<div className="div-block-subtitle">
					<h3>General</h3>
				</div>
				<FormGeneralInfoV1
					profile={`${process.env.REACT_APP_WEB_API_HOST}/backend/uploads/images/${this.state.logo}`}
					venueId={this.venueId}
					isVenueReadOnly={this.state.isVenueReadOnly}
					onVenueDClick={this.onVenueDClickHandler}
					venue={this.state.venue}
					onVenueChange={this.onVenueChangeHandler}
					isDescribeReadOnly={this.state.isDescribeReadOnly}
					onDescDClick={this.onDescDClickHandler}
					desc={this.state.desc}
					onDescChange={this.onDescChangeHandler}
					onVenueSave={this.onVenueSaveHandler}
					isProvinceReadOnly={this.state.isProvinceReadOnly}
					onProvinceDClick={this.onProvinceDClickHandler}
					province={this.state.province}
					onProvinceChange={this.onProvinceChangeHandler}
					isCityReadOnly={this.state.isCityReadOnly}
					onCityDClick={this.onCityDClickHandler}
					city={this.state.city}
					onCityChange={this.onCityChangeHandler}
					isAddressReadOnly={this.state.isAddressReadOnly}
					onAddressDClick={this.onAddressDClickHandler}
					add={this.state.add}
					onAddChange={this.onAddChangeHandler}
					isPostReadOnly={this.state.isPostReadOnly}
					onPostDClick={this.onPostDClickHandler}
					post={this.state.post}
					onPostChange={this.onPostChangeHandler}
				/>
				<br />
				<br />
				<ColoredLine color="black" padding={0.5} />
				<div className="div-block-subtitle">
					<h3>Social Links</h3>
				</div>
				<FormSocials
					isWebsiteReadOnly={this.state.isWebsiteReadOnly}
					onWebsiteDClick={this.onWebsiteDClickHandler}
					website={this.state.website}
					onWebsiteChange={this.onWebsiteChangeHandler}
					isGoogleReadOnly={this.state.isGoogleReadOnly}
					onGoogleDClick={this.onGoogleDClickHandler}
					google={this.state.google}
					onGoogleChange={this.onGoogleChangeHandler}
					isAppleReadOnly={this.state.isAppleReadOnly}
					onAppleDClick={this.onAppleDClickHandler}
					apple={this.state.apple}
					onAppleChange={this.onAppleChangeHandler}
					isFacebookReadOnly={this.state.isFacebookReadOnly}
					onFacebookDClick={this.onFacebookDClickHandler}
					facebook={this.state.facebook}
					onFacebookChange={this.onFacebookChangeHandler}
					isInstagramReadOnly={this.state.isInstagramReadOnly}
					onInstagramDClick={this.onInstagramDClickHandler}
					instagram={this.state.instagram}
					onInstagramChange={this.onInstagramChangeHandler}
					isTwitterReadOnly={this.state.isTwitterReadOnly}
					onTwitterDClick={this.onTwitterDClickHandler}
					twitter={this.state.twitter}
					onTwitterChange={this.onTwitterChangeHandler}
					onSocialsSave={this.onSocialsSaveHandler}
				/>
				<ColoredLine color="black" padding={0.5} />
				<div className="div-block-subtitle">
					<h3>Operation Hours</h3>
				</div>
				{this.FormOperationHoursV1(this.state)}
				<ColoredLine color="black" padding={0.5} />
				<div className="div-block-subtitle">
					<h3>Cameras</h3>
				</div>
				<FormCameras
					cameras={this.state.cameras}
					onFormCameraItemClick={this.onFormCameraItemClickHandler}
					onFormCameraItemDelClick={
						this.onFormCameraItemDelClickHandler
					}
					onFormCameraAddClick={this.onFormCameraAddClickHandler}
				/>
				<CameraPopup
					show={this.state.isCameraPopShow}
					handleClose={this.handleCloseHandler}
					handleEditSave={this.handleEditSaveHandler}
					camTNum={this.state.camTNum}
					camKey={this.state.camKey}
					camId={this.state.camId}
					_camId={this.state._camId}
					camDesc={this.state.camDesc}
					camHeader={this.state.camHeader}
					onCamTNumChange={this.onCamTNumChangeHandler}
					onCamKeyChange={this.onCamKeyChangeHandler}
					onCamIdChange={this.onCamIdChangeHandler}
					onCamDescChange={this.onCamDescChangeHandler}
				/>
				<CameraPopupAdd
					show={this.state.isCameraPopAddShow}
					handleClose={this.handleCloseAddHandler}
					camAddSave={this.camAddSaveHandler}
					camTNum={this.state.camTNumAdd}
					camKey={this.state.camKeyAdd}
					camId={this.state.camIdAdd}
					camDesc={this.state.camDescAdd}
					camHeader={this.state.camHeaderAdd}
					onCamTNumChange={this.onCamTNumAddChangeHandler}
					onCamKeyChange={this.onCamKeyAddChangeHandler}
					onCamIdChange={this.onCamIdAddChangeHandler}
					onCamDescChange={this.onCamDescAddChangeHandler}
				/>
				<CameraPopupDel
					show={this.state.isCameraPopDelShow}
					handleClose={this.handleCloseDelHandler}
					handleOkDel={this.handleOkDelHandler}
					camTNum={this.state.camTNum}
					camKey={this.state.camKey}
					camId={this.state.camId}
					camDesc={this.state.camDesc}
					_camId={this.state._camId}
				/>
			</div>
		)
	}
}

// get auth context
VenueSettings.contextType = AuthContext
export default VenueSettings
