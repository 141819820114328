/**
 * different function work on different database server port.
 *
 * @version 1.0.0
 * @author Nemo(xwang59@academic.rrc.ca)
 */
export const PORTS = {
	DFT: {
		USR: 3050,
		VDO: 3050,
		STM: 3050,
	},
	BAC: {
		USR: 5050,
		VDO: 5050,
		STM: 5050,
	},
}
