import React from 'react'

const ToastContext = React.createContext()

function useToast() {
	const context = React.useContext(ToastContext)
	if (!context) {
		throw new Error('Cannot use toast context without a ToastProvider')
	}
	const [toasts, setToasts] = context

	const addToast = (newToast) => {
		toasts.push(newToast)
		setToasts(toasts)
	}

	const removeToast = (index) => {
		setToasts(toasts.splice(index + 1, 1))
	}

	return { toasts, setToasts, addToast, removeToast }
}

function ToastProvider(props) {
	const [toasts, setToasts] = React.useState([])
	const value = React.useMemo(() => [toasts, setToasts], [toasts])
	return <ToastContext.Provider value={value} {...props} />
}

export { ToastProvider, useToast }
