import React from 'react'

const FeedbackTest = () => {
	return (
		<iframe
			title="title"
			loading="lazy"
			src="https://docs.google.com/forms/d/e/1FAIpQLSdFF0tEdJ_8c7wMen0GcNUjPdZDCJWOEk6Ff-BsipJwKFuKtA/viewform?embedded=true"
			marginHeight="0"
			marginWidth="0"
			width="700"
			height="520"
			frameBorder="0"
		>
			Loading…
		</iframe>
	)
}

export default FeedbackTest
