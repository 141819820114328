import React, { useState, useRef } from 'react'
import { Container, Col, Row, Form, Button, Tabs, Tab } from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'

// Clients Google Forms
import LeagueFeedbackForm from '../../components/GoogleForms/feedbackTestLeague'
import PlayerFeedbackForm from '../../components/GoogleForms/feedbackTestPlayer'
import VenueFeedbackForm from '../../components/GoogleForms/feedbackTestVenue'
// Email sending class
import EmailSending from '../..//classes/emailSending'
// Style sheet
import './contact.css'

//Key for the ReCAPTHCHA
const ReCAPTCHA_Key = '6Lf-ZjYbAAAAAMZfKvr8Gvgu3guCQWluMYpsk8zH'

const SendEmail = new EmailSending()

/**
 * Checks given email against regex to see if it's valid
 * @param {string} email Email to validate
 * @returns true if email is valid, false if email is not valid
 */
function emailValidate(email) {
	console.log('Email Validate Start')
	const reg =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	console.log('Email Validate End')
	return reg.test(String(email).toLowerCase())
}

const Contact = () => {
	const [email, setEmail] = useState('')
	const [validated, setValidated] = useState(false)
	const [error, setError] = useState('')
	const [emailError, setEmailError] = useState('')
	const [token, setToken] = useState('')
	const [feedbackFormHidden, setHidden] = useState(true)
	const [name, setName] = useState('')
	const [message, setMessage] = useState('')

	const recaptchaRef = useRef()

	const onSubmit = (e) => {
		const isEmailValid = emailValidate(email)

		const form = e.currentTarget

		try {
			if (form.checkValidity() === false) {
				e.preventDefault()
				e.stopPropagation()
			}

			setValidated(true)
		} catch (error) {
			alert('Validation Error: ' + error)
		}

		if (!isEmailValid && validated) {
			console.log('Email Not Valid')
			setEmailError('Please Enter valid Email')
			e.preventDefault()
			e.stopPropagation()
		}

		try {
			if (!token) {
				setError('Captcha must be done')
				e.preventDefault()
				e.stopPropagation()
			} else {
				setError('')

				// ? Supposed to contact google and check if captcha token is valid
				/* Axios.post(`http://localhost:3050/captcha`, {
          token,
          email: "billycamtest@gmail.com"
        }).then(resp => {
            recaptchaRef.current.reset();
            setToken("");
          }).finally(() => {
            recaptchaRef.current.reset();
            setToken("");
          }) */

				try {
					// Checks to see if form is filled out and correct
					console.log(
						form.checkValidity() === true && isEmailValid === true
					)
					if (
						form.checkValidity() === true &&
						isEmailValid === true
					) {
						e.preventDefault()
						setEmailError('')
						SendEmail.contactEmail(name, message, email)
					}
				} catch (error) {
					alert('Email Sending Error: ' + error)
				}
			}
		} catch (error) {
			console.log('ReCAPTCHA Error: ' + error)
		}
	}

	return (
		<Container>
			<Row>
				<Col>
					<h1>CONTACT US</h1>
					<ul className="contactUL">
						<li>We want to hear from you!</li>
						<li>So please tell us about yourself;</li>
						<li>
							Are you a casual player, a league player? Are you a
							league facilitator? A venue owner?
						</li>
						<li>How can we help you?</li>
					</ul>
				</Col>
				<Col className="contactFormCol">
					<Form
						noValidate
						validated={validated}
						onSubmit={onSubmit}
						id="contactform"
					>
						<Row>
							<Form.Group as={Col} controlId="name">
								<Form.Control
									type="text"
									placeholder="Name"
									name="name"
									onChange={(e) => setName(e.target.value)}
									required
								/>
								<Form.Control.Feedback type="invalid">
									Please provide your Name.
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} controlId="email">
								<Form.Control
									type="email"
									placeholder="Email"
									name="email"
									required
									onChange={(e) => setEmail(e.target.value)}
								/>
								<Form.Control.Feedback type="invalid">
									Please provide a valid Email.
								</Form.Control.Feedback>
								<p className="text-danger">{emailError}</p>
							</Form.Group>
						</Row>
						<Form.Group controlId="message">
							<Form.Control
								as="textarea"
								placeholder="Message"
								style={{ height: '100px' }}
								name="message"
								onChange={(e) => setMessage(e.target.value)}
								required
							/>
							<Form.Control.Feedback type="invalid">
								Please provide a message.
							</Form.Control.Feedback>
						</Form.Group>
						<Row>
							<Col className="ReCaptcha">
								<ReCAPTCHA
									ref={recaptchaRef}
									sitekey={ReCAPTCHA_Key}
									onChange={(token) => setToken(token)}
									onExpired={() => setToken('')}
								/>
								<p className="text-danger">{error}</p>
							</Col>
							<Col>
								<Button type="submit">Send Email</Button>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
			<Button
				onClick={() => {
					setHidden(!feedbackFormHidden)
				}}
				id="form-tags-div"
			>
				Give FeedBack
			</Button>
			<Row hidden={feedbackFormHidden}>
				<Col>
					<Tabs
						justify
						defaultActiveKey="player"
						id="form-tags"
						className="justify-content-center"
					>
						<Tab eventKey="player" title="Players">
							<PlayerFeedbackForm />
						</Tab>
						<Tab eventKey="venue" title="Venue Owners">
							<VenueFeedbackForm />
						</Tab>
						<Tab eventKey="league" title="League Facilitators">
							<LeagueFeedbackForm />
						</Tab>
					</Tabs>
				</Col>
				<Col></Col>
			</Row>
		</Container>
	)
}

export default Contact
