import Card from 'react-bootstrap/Card'

/**
 * camera tile component
 *
 * @version 1.0.0
 * @author Nemo(xwang59@academic.rrc.ca)
 */
const CameraItem = (props) => {
	let color = props.color.toLowerCase()

	return (
		<Card
			bg={color}
			text={color === 'light' ? 'dark' : 'white'}
			style={{ width: '18rem' }}
			className="mb-2"
			onClick={(e) => {
				e.stopPropagation()
				props.onCameraItemClick(props)
			}}
		>
			<Card.Header>
				{props.header}
				<span
					onClick={(e) => {
						e.stopPropagation()
						props.onCameraItemDelClick(props)
					}}
				>
					x
				</span>
			</Card.Header>
			<Card.Body>
				<Card.Text>Table Number: {props.ctable}</Card.Text>
				<Card.Text>Public Key: {props.ckey}</Card.Text>
				<Card.Text>Camera ID: {props.cid}</Card.Text>
				<Card.Text>Description: {props.cdesc}</Card.Text>
			</Card.Body>
		</Card>
	)
}

const CameraAdd = (props) => {
	return (
		<Card
			text={'dark'}
			id={'camera-add'}
			style={{ width: '18rem' }}
			className="mb-2"
			onClick={props.onCameraAddClick}
		>
			<Card.Body>
				<Card.Title>Add Camera </Card.Title>
				<div className="ver-fk">
					<Card.Text>+</Card.Text>
				</div>
			</Card.Body>
		</Card>
	)
}

export { CameraItem, CameraAdd }
