import emailjs from 'emailjs-com'
import axios from 'axios'

class EmailSending {
	constructor() {
		this.state = {
			forgotContactUser: 'user_2DnAF5FMrsvGWwLpA03Qh',
			verifyChangeUser: 'user_vT6mQpRO1168s0W71Sz8n',
		}
	}

	contactEmail(name, message, email) {
		var templateParams = {
			name: name,
			email: email,
			message: message,
		}

		emailjs
			.send(
				'service_9xle6ql',
				'template_hz6p6d3',
				templateParams,
				this.state.forgotContactUser
			)
			.then(
				(result) => {
					console.log(result.text)
					// TODO Change to toast instead of alert
					alert('Email sent successfully')
					window.location.reload()
				},
				(error) => {
					console.log(error.text)
					alert(error.text)
				}
			)
	}

	async forgotPasswordEmail(email, reset_link) {
		var templateParams = {
			email: email,
			reset_link: `${process.env.REACT_APP_WEB_FRONTEND_HOST}/login/${reset_link}`,
		}

		axios.post(`${process.env.REACT_APP_WEB_API_HOST}/codes/createCode`, {
			email: email,
			code: reset_link,
			type: 'forgotten',
		})

		await emailjs
			.send(
				'service_9xle6ql',
				'template_hcwg08b',
				templateParams,
				this.state.forgotContactUser
			)
			.then(
				(result) => {
					console.log(result.text)
					// TODO Change to toast instead of alert
					alert('Password reset link sent successful.')
				},
				(error) => {
					console.log(error.text)
					throw error
				}
			)
	}

	verifyAccountEmail(email, verify_link) {
		var templateParams = {
			email: email,
			verify_link: `${process.env.REACT_APP_WEB_FRONTEND_HOST}/login/${verify_link}`,
		}

		axios.post(`${process.env.REACT_APP_WEB_API_HOST}/codes/createCode`, {
			email: email,
			code: verify_link,
			type: 'verification',
		})

		emailjs
			.send(
				'service_0ymev1v',
				'template_hbwkhvn',
				templateParams,
				this.state.verifyChangeUser
			)
			.then(
				(result) => {
					console.log(result.text)
					// TODO Change to toast instead of alert
					alert('Verify Account Email has been sent.')
				},
				(error) => {
					console.log(error.text)
					throw error
				}
			)
	}

	changesOccuredEmail(email, change) {
		var templateParams = {
			email: email,
			change: change,
		}

		emailjs
			.send(
				'service_0ymev1v',
				'template_td8w7ru',
				templateParams,
				this.state.verifyChangeUser
			)
			.then(
				(result) => {
					console.log(result.text)
					// TODO Change to toast instead of alert
					alert('Change Occurred Email has been sent.')
				},
				(error) => {
					console.log(error.text)
					throw error
				}
			)
	}
}

export default EmailSending
