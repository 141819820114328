import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../shared/context/auth-context'
import { Table, Button } from 'react-bootstrap'
import axios from 'axios'

/**
 * Will dispaly the favorites for the user
 */

const Favorites = () => {
	const [favorites, setFavorites] = useState([])
	const auth = useContext(AuthContext)

	useEffect(() => {
		async function getFavorites() {
			if (auth.isLoggedIn) {
				let result = await axios.get(
					`${process.env.REACT_APP_WEB_API_HOST}/user/favorites/${auth.userId}`
				)

				setFavorites(result.data)
			}
		}
		getFavorites()
	}, [auth.isLoggedIn, auth.userId])

	const removeFromFavorites = async (venueID) => {
		await axios.post(
			`${process.env.REACT_APP_WEB_API_HOST}/user/favorite/remove/${auth.userId}/${venueID}`
		)

		async function getFavorites() {
			if (auth.isLoggedIn) {
				let result = await axios.get(
					`${process.env.REACT_APP_WEB_API_HOST}/user/favorites/${auth.userId}`
				)

				setFavorites(result.data)
			}
		}
		getFavorites()
	}

	return (
		<>
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>#</th>
						<th>Venue Name</th>
						<th>Venue Address</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{!favorites.length > 0 ? (
						<tr></tr>
					) : (
						favorites.map((favorite, i) => (
							<tr key={i}>
								<td>{i + 1}</td>
								<td>
									<a href={`/venuePage/${favorite.venueID}`}>
										{favorite.venueName}
									</a>
								</td>
								<td>{favorite.venueAddress}</td>
								<td>
									<Button
										onClick={() =>
											removeFromFavorites(
												favorite.venueID
											)
										}
									>
										Remove
									</Button>
								</td>
							</tr>
						))
					)}
				</tbody>
			</Table>
		</>
	)
}

export default Favorites
