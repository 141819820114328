import React from 'react'
import { Button } from 'react-bootstrap'
import axios from 'axios'
// import FormData from 'form-data';

import './ImageUpload.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

const ImgUpload = ({ onChange, src }) => (
	<label htmlFor="photo-upload" className="custom-file-upload fas">
		<div className="img-wrap img-upload">
			<img htmlFor="photo-upload" alt="" src={src} />
		</div>
		<input id="photo-upload" type="file" onChange={onChange} />
	</label>
)

const Edit = ({ onSubmit, children }) => (
	<div className="image-upload">
		{/* <form onSubmit={onSubmit}> */}
		{children}
		{/* </form> */}
	</div>
)

class ImageUpload extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			fileChanged: false,
			file: Blob,
			imagePreviewUrl: this.props.src,
		}
	}

	photoUpload = (e) => {
		e.preventDefault()
		const reader = new FileReader()
		const file = e.target.files[0]
		reader.onloadend = () => {
			this.setState({
				fileChanged: true,
				file: file,
				imagePreviewUrl: reader.result,
			})
		}
		reader.readAsDataURL(file)
	}

	saveImage = (e) => {
		e.preventDefault()

		if (this.state.fileChanged) {
			const imgData = new FormData()

			imgData.append('venueId', this.props.venueId)
			imgData.append('venueImg', this.state.file)

			axios
				.post(
					`${process.env.REACT_APP_WEB_API_HOST}/venues-padding/image-upload-v4`,
					imgData,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
				)
				.then((res) => {
					console.log(res.data.result)
					if (res.data.status) {
						this.setState({
							fileChanged: false,
						})
					}
				})
				.catch((error) => {
					console.log('There was an error!', error)
				})
		}
	}

	render() {
		return (
			<Edit onSubmit={this.handleSubmit}>
				<ImgUpload
					onChange={this.photoUpload}
					src={
						this.state.fileChanged
							? this.state.imagePreviewUrl
							: this.props.src
					}
				/>
				<div className="btn-save">
					<Button
						variant="warning"
						type="submit"
						onClick={this.saveImage}
					>
						Save
					</Button>
				</div>
			</Edit>
		)
	}
}

export default ImageUpload
