import React from 'react'
import { Form } from 'react-bootstrap'
import { CameraAdd } from './CameraItem'
import CameraAll from './CameraAll'

/**
 * cameras component
 *
 * @version 1.0.0
 * @author Nemo(xwang59@academic.rrc.ca)
 */
const FormCameras = (props) => {
	// let colorIndex = Math.floor(Math.random() * cameraColors.length) + 0;
	return (
		<Form>
			<Form.Group className="mb-3 mb-3-">
				<CameraAll
					cameras={props.cameras}
					onFormCameraItemClick={props.onFormCameraItemClick}
					onFormCameraItemDelClick={props.onFormCameraItemDelClick}
				/>
				<CameraAdd
					onCameraAddClick={() => {
						props.onFormCameraAddClick(props.cameras.length)
					}}
				/>
			</Form.Group>
		</Form>
	)
}

export default FormCameras
