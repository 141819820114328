import { Modal, Button, Form } from 'react-bootstrap'

/**
 * camera popup editting component
 *
 * @version 1.0.0
 * @author Nemo(xwang59@academic.rrc.ca)
 */
const CameraPopup = (props) => {
	return (
		<>
			<Modal
				show={props.show}
				onHide={props.handleClose}
				backdrop="static"
				keyboard={true}
				centered
				size="lg"
			>
				<Modal.Header>
					<Modal.Title>{props.camHeader}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Label>Camera ID:</Form.Label>
						<Form.Control
							type="text"
							readOnly={false}
							value={props.camId}
							onChange={props.onCamIdChange}
						/>

						<Form.Label>Public Key:</Form.Label>
						<Form.Control
							type="text"
							readOnly={false}
							value={props.camKey}
							onChange={props.onCamKeyChange}
						/>

						<Form.Group className="mb-3">
							<Form.Label>Table Number:</Form.Label>
							<Form.Control
								type="text"
								readOnly={false}
								value={props.camTNum}
								onChange={props.onCamTNumChange}
							/>

							<Form.Label>Description:</Form.Label>
							<Form.Control
								as="textarea"
								rows={2}
								readOnly={false}
								value={props.camDesc}
								onChange={props.onCamDescChange}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={props.handleClose}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							props.handleEditSave(props)
						}}
					>
						Save
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default CameraPopup
