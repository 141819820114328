import React from 'react'
import { Card } from 'react-bootstrap'

const ImageFigure = (props) => {
	return (
		<Card className="text-center">
			<Card.Body>
				<Card.Title>
					<h2>{props.caption}</h2>
				</Card.Title>
			</Card.Body>
			<Card.Img
				width="408"
				variant="bottom"
				src={require('../../assets/' + props.image).default}
			/>
		</Card>
	)
}

export default ImageFigure
