import { React } from 'react'
import { Tab } from 'react-bootstrap'
import TimeRangePicker from '@wojtekmaj/react-timerange-picker/dist/entry.nostyle'
import './TimeRangePicker.css'
import 'react-clock/dist/Clock.css'

/**
 * Venue settings elements.
 *
 * @version 1.0.0
 * @author Nemo(xwang59@academic.rrc.ca)
 */

// colored line, similiar as <hr />
const ColoredLine = (props) => (
	<hr
		style={{
			color: props.color,
			backgroundColor: props.color,
			padding: props.padding,
		}}
	/>
)

// time range picker wrapper
const WeekdayContent = (props) => {
	return (
		<TimeRangePicker
			locale="en"
			clockIcon={null}
			disableClock={true}
			rangeDivider="->"
			value={props.period}
			className={props.which}
			onChange={props.onChange}
		/>
	)
}

// discarded
const TableWeekdayV2 = (props) => {
	console.log(props.weekday)
	return (
		<Tab eventKey={props.weekday} title={props.weekday}>
			<WeekdayContent period={props.time} />
		</Tab>
	)
}

// discarded
const TableWeekdayV3 = (props) => {
	console.log(`TableWeekdayV3: ${props.weekday}`)
	return (
		<Tab eventKey={props.weekday} title={props.weekday}>
			<TimeRangePicker value={props.period} />
		</Tab>
	)
}

export { WeekdayContent, ColoredLine, TableWeekdayV2, TableWeekdayV3 }
