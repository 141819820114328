import React from 'react'
import { Button, Form } from 'react-bootstrap'

/**
 * social links component.
 *
 * @version 1.0.0
 * @author Nemo(xwang59@academic.rrc.ca)
 */
const FormSocials = (props) => (
	<Form>
		<Form.Group className="mb-3">
			<Form.Label>Website</Form.Label>
			<Form.Control
				type="text"
				onDoubleClick={props.onWebsiteDClick}
				value={props.website}
				onChange={props.onWebsiteChange}
				id="inputWebsite"
			/>

			<Form.Label>Google Map</Form.Label>
			<Form.Control
				type="text"
				onDoubleClick={props.onGoogleDClick}
				value={props.google}
				onChange={props.onGoogleChange}
				id="inputGoogle"
			/>

			<Form.Label>Apple Map</Form.Label>
			<Form.Control
				type="text"
				onDoubleClick={props.onAppleDClick}
				value={props.apple}
				onChange={props.onAppleChange}
				id="inputApple"
			/>

			<Form.Label>Facebook</Form.Label>
			<Form.Control
				type="text"
				onDoubleClick={props.onFacebookDClick}
				value={props.facebook}
				onChange={props.onFacebookChange}
				id="inputFacebook"
			/>

			<Form.Label>Instagram</Form.Label>
			<Form.Control
				type="text"
				onDoubleClick={props.onInstagramDClick}
				value={props.instagram}
				onChange={props.onInstagramChange}
				id="inputInstagram"
			/>

			<Form.Label>Twitter</Form.Label>
			<Form.Control
				type="text"
				onDoubleClick={props.onTwitterDClick}
				value={props.twitter}
				onChange={props.onTwitterChange}
				id="inputTwitter"
			/>

			<div className="btn-align-right">
				<Button
					variant="warning"
					type="submit"
					onClick={props.onSocialsSave}
				>
					Save
				</Button>
			</div>
		</Form.Group>
	</Form>
)

export default FormSocials
