import { Modal, Button, Form } from 'react-bootstrap'

/**
 * camera popup deleting component
 *
 * @version 1.0.0
 * @author Nemo(xwang59@academic.rrc.ca)
 */
const CameraPopupDel = (props) => {
	return (
		<>
			<Modal
				show={props.show}
				onHide={props.handleClose}
				backdrop="static"
				keyboard={true}
				centered
				size="lg"
			>
				<Modal.Header>
					<Modal.Title className="cam-title-del">
						Camera Delete...
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Label className="cam-del">
							Are you sure to remove:{' '}
						</Form.Label>
						<br />
						<Form.Label>Camera ID: {props.camId}</Form.Label>
						<br />
						<Form.Label>Public Key: {props.camKey}</Form.Label>
						<br />
						<Form.Label>Table Number: {props.camTNum}</Form.Label>
						<br />
						<Form.Label>
							{'>'} {props.camDesc}
						</Form.Label>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={props.handleClose}>
						Cancel
					</Button>
					<Button
						variant="danger"
						onClick={(e) => {
							e.stopPropagation()
							props.handleOkDel(props)
						}}
					>
						OK
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default CameraPopupDel
