import React from 'react'
import { Container } from 'react-bootstrap'
import CodesForm from '../../components/codes/codesForm'

const Codes = () => {
	return (
		<Container>
			<CodesForm />
		</Container>
	)
}

export default Codes
