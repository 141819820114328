function API_EmailValidate(email) {
	const reg =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return reg.test(String(email).toLowerCase())
}

function API_PasswordValidate(pass) {
	// at least one number, one lowercase, one uppercase letter and one special character
	// at least six characters
	const reg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}/
	return reg.test(pass)
}

export { API_EmailValidate, API_PasswordValidate }
