import React from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faFacebook,
	faInstagram,
	faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import logo from '../../assets/BillycamIcon.png'
import './footer.css'

const iconSize = '2x'

const Footer = () => {
	return (
		<footer>
			<Navbar id="globalFooter" fixed="bottom">
				<Navbar.Brand href="/home">
					<img
						src={logo}
						width="40"
						height="40"
						className="d-inline-block align-top"
						alt="BillyCam Ball logo"
					/>
				</Navbar.Brand>

				<Nav>
					<Nav.Item>
						<Nav.Link href="/termsandconditions">
							Terms and Conditions
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link href="/privacypolicy">
							Privacy Policy
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<Navbar.Collapse className="justify-content-end">
					<Nav>
						<Nav.Item>
							<Nav.Link
								href="https://www.facebook.com/I-See-Sports-107428807312125/"
								target="_blank"
							>
								<FontAwesomeIcon
									icon={faFacebook}
									size={iconSize}
								/>
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link href="/#" target="_blank" hidden>
								<FontAwesomeIcon
									icon={faTwitter}
									size={iconSize}
								/>
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link href="/#" target="_blank" hidden>
								<FontAwesomeIcon
									icon={faInstagram}
									size={iconSize}
								/>
							</Nav.Link>
						</Nav.Item>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</footer>
	)
}

export default Footer
