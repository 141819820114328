import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import EmailSending from '../../classes/emailSending'
import Favorites from '../../components/users/Favorites'
import { AuthContext } from '../../shared/context/auth-context'
import '@fortawesome/fontawesome-free/css/all.css'
import './AccountSettings.css'

const SendEmail = new EmailSending()

//<i>
//shape: fa-edit...
//size:  fa-xs,fa-sm,fa-lg,fa-2x, fa-3x,fa-5x,fa-7x,fa-10x...
//color: blue-color, xx-color...
const ICEditVi = <i className="fas fa-edit fa-lg blue-color" />

const SepcialPassError = (
	<span>
		Password must be:
		<ul>
			<li>At least 6 characters</li>
			<li>At least one number,</li>
			<li>At least one lowercase,</li>
			<li>At least one uppercase letter,</li>
			<li>At least one special character(!@#$%^&*) </li>
		</ul>
	</span>
)

/**
 * Administrator settings page.
 *
 * @version 1.0.0
 * @author Nemo(xwang59@academic.rrc.ca)
 * @author Cole(cbockstael@academic.rrc.ca)
 */
class AccountSettings extends Component {
	constructor(prop) {
		super(prop)

		this.state = {
			email: prop.userInfo === undefined ? '' : this.props.userInfo.email,
			isEmailShow: false,
			password1: '',
			password2: '',
			isPasswdChgShow: false,
			serverMsg: '',
			serverMsgPass: '',
			redirect: '/login/',
			passChged: false,
		}
	}

	// save email handler
	saveEmailHandler = () => {
		axios
			.post(
				`${process.env.REACT_APP_WEB_API_HOST}/user/update-email-v2/`,
				{
					id: this.props.userInfo.id,
					email: this.state.email,
					email_backup: this.state.email_backup,
				}
			)
			.then((res) => {
				if (res.data.status) {
					if (res.data.status) {
						this.context.login(
							res.data.userId,
							res.data.userRole,
							this.state.email,
							res.data.userToken
						)
					}

					this.setState({
						isEmailShow: false,
						email_backup: this.state.email,
					})

					SendEmail.changesOccuredEmail(
						this.state.email_backup,
						'Email'
					)
				} else {
					this.setState({
						serverMsg: res.data.result,
					})
				}
			})
			.catch((error) => {
				console.log('There was an error!', error)
			})
	}

	// save password handler
	savePasswordHandler = () => {
		axios
			.post(
				`${process.env.REACT_APP_WEB_API_HOST}/user/update-pass-v2/`,
				{
					id: this.props.userInfo.id,
					password: this.state.password,
					password_backup: this.state.password_backup,
					password1: this.state.password1,
					password2: this.state.password2,
				}
			)
			.then((res) => {
				if (res.data.specialError) {
					this.setState({
						isPassSpecialErrorShow: true,
					})
				} else {
					this.setState({
						serverMsgPass: res.data.result,
						passChged: true,
					})
				}

				if (res.data.status) {
					SendEmail.changesOccuredEmail(
						this.state.email_backup,
						'Password'
					)

					setTimeout(() => {
						this.context.logout()
						window.location.href = '/'
					}, 1000)
				}
			})
			.catch((error) => {
				console.log('There was an error!', error)
			})
	}

	// for restoring current email when email editting canceled
	componentDidMount() {
		this.setState({
			email_backup: this.state.email,
		})
	}

	// email button clicked
	emailEditClick = () => {
		this.setState({
			isEmailShow: true,
			serverMsg: '',
			email: this.state.email_backup,
		})
	}

	// password button clicked
	passEditClick = () => {
		this.setState({
			isPasswdChgShow: true,
			serverMsgPass: '',
		})
	}

	redirectBug = 0
	render() {
		this.redirectBug++
		if (this.redirectBug >= 2) {
			if (!this.context.isLoggedIn) {
				return <Redirect to={this.state.redirect} />
			}
		}

		const DivEmailSource = (
			<div>
				<p className="p-editable">{this.state.email}</p>
				<Button variant="link" onClick={this.emailEditClick}>
					{ICEditVi} Edit
				</Button>
			</div>
		)

		const DivEmailEdit = (
			<div className="div-show">
				<input
					className="inputs"
					type="email"
					value={this.state.email}
					onChange={(e) => this.setState({ email: e.target.value })}
				/>
				<Button variant="secondary" onClick={this.saveEmailHandler}>
					Save
				</Button>
				{` `}
				<Button
					variant="secondary"
					onClick={() =>
						this.setState({
							isEmailShow: false,
							serverMsg: '',
							email: this.state.email_backup,
						})
					}
				>
					Cancel
				</Button>
			</div>
		)

		const DivPassSource = (
			<div>
				<p className="p-editable">{`****************`}</p>
				<Button variant="link" onClick={this.passEditClick}>
					{ICEditVi} Edit
				</Button>
			</div>
		)

		const DivPassEdit = (
			<div className="div-show div-show-passwd">
				<p>Old password</p>
				<input
					className="inputs"
					type="password"
					onChange={(e) =>
						this.setState({ password: e.target.value })
					}
				/>
				<p>New password</p>
				<input
					className="inputs"
					type="password"
					value={this.state.password1}
					onFocus={() =>
						this.setState({
							isPassSpecialErrorShow: false,
							serverMsgPass: '',
						})
					}
					onChange={(e) =>
						this.setState({ password1: e.target.value })
					}
				/>
				<p>Repeat new password</p>
				<div className="div-show-passwd-conform">
					{this.state.isPassSpecialErrorShow
						? SepcialPassError
						: null}
					<input
						className="inputs"
						type="password"
						value={this.state.password2}
						onFocus={() =>
							this.setState({
								isPassSpecialErrorShow: false,
								serverMsgPass: '',
							})
						}
						onChange={(e) =>
							this.setState({ password2: e.target.value })
						}
					/>
					<Button
						variant="success"
						onClick={this.savePasswordHandler}
					>
						Save
					</Button>
					{` `}
					<Button
						variant="secondary"
						onClick={() =>
							this.setState({
								isPasswdChgShow: false,
								serverMsgPass: '',
								password: this.state.password_backup,
								password1: '',
								password2: '',
								isPassSpecialErrorShow: false,
							})
						}
					>
						Cancel
					</Button>
				</div>
			</div>
		)

		return (
			<div className="com-acctsetting">
				<div className="div-block div-block-ps">
					<h2>Profile Settings</h2>
				</div>
				<div className="div-block">
					<h3>Account Settings</h3>
					<p>My email address: </p>
					{this.state.isEmailShow ? DivEmailEdit : DivEmailSource}
					{this.state.serverMsg ? (
						<div className="error">{this.state.serverMsg}</div>
					) : null}

					<p>Password: </p>
					{this.state.isPasswdChgShow ? DivPassEdit : DivPassSource}
					{this.state.serverMsgPass ? (
						<div className="error">{this.state.serverMsgPass}</div>
					) : null}
				</div>
				<div className="div-block">
					<h3>Favorite settings</h3>
					<Favorites />
				</div>
			</div>
		)
	}
}

AccountSettings.contextType = AuthContext
export default AccountSettings
