import React from 'react'
import { Breadcrumb, Button, Card } from 'react-bootstrap'
import './StreamList.css'

/**
 * Will dispaly the favorites for the user
 */

const Streams = () => {
	return (
		<>
			<h1>Livestream</h1>
			<Breadcrumb>
				<Breadcrumb.Item href="/"> Home </Breadcrumb.Item>
				<Breadcrumb.Item active> Livestream </Breadcrumb.Item>
			</Breadcrumb>
			<Card style={{ width: '30rem' }}>
				<Card.Body>
					<Card.Title>Youtube</Card.Title>
					<Card.Text>Check us out on Youtube</Card.Text>
					<Card.Text>
						<Button
							href="https://www.youtube.com/@steveupton9675/streams"
							variant="primary"
						>
							Go to livestream
						</Button>
					</Card.Text>
				</Card.Body>
			</Card>
		</>
	)
}

export default Streams
