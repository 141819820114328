import React from 'react'
import { Breadcrumb, Card, Button } from 'react-bootstrap'

// This component gets all venues from the api, and passes a map of the venues to VenueItem to be displayed
const Venues = () => {
	return (
		<>
			<h1>Recordings</h1>
			<Breadcrumb>
				<Breadcrumb.Item href="/"> Home </Breadcrumb.Item>
				<Breadcrumb.Item active> Livestream </Breadcrumb.Item>
			</Breadcrumb>
			<Card style={{ width: '30rem' }}>
				<Card.Body>
					<Card.Title>Youtube</Card.Title>
					<Card.Text>Check us out on Youtube</Card.Text>
					<Card.Text>
						<Button
							href="https://www.youtube.com/@steveupton9675/videos"
							variant="primary"
						>
							Go to recordings
						</Button>
					</Card.Text>
				</Card.Body>
			</Card>
		</>
	)
}

export default Venues
