import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import axios from 'axios'
import StreamCard from '../streams/StreamCard'

/**
 * Will display all of the streams for a single venue
 */

const VenueStreams = ({ venueInfo }) => {
	const [streams, setStreams] = useState([])
	let filteredStreams

	useEffect(() => {
		const getStreamList = async () => {
			const result = await axios.get(
				`${process.env.REACT_APP_CONTROL_HOST}/stream/list.json`
			)
			setStreams(result.data)
		}

		getStreamList()
	}, [])

	if (streams) {
		filteredStreams = streams.reverse().filter((filtered) => {
			if (filtered.venueId === venueInfo.venueNumber) {
				return filtered
			} else {
				return null
			}
		})
	}

	return (
		<>
			<Row>
				{filteredStreams.length === 0
					? 'This venue has no current livestreams.'
					: filteredStreams.map((stream) => (
							<Col sm={12} md={6} lg={4} xl={3} key={stream.key}>
								<StreamCard
									key={stream.key}
									stream={stream}
									streamKey={stream.key}
								/>
							</Col>
					  ))}
			</Row>
		</>
	)
}

export default VenueStreams
