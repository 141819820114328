import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Container } from 'react-bootstrap'
import ResetForm from './resetForm'
import InvalidCode from './invalidCode'
import Validated from './verified'
import { useParams } from 'react-router'
import './codes.css'

const CodesForm = () => {
	const [codeObject, setCodeObject] = useState([])
	const code = useParams().code

	// Get's code from database if it exists
	useEffect(() => {
		try {
			const getCode = async () => {
				const result = await axios.get(
					`${process.env.REACT_APP_WEB_API_HOST}/codes/validateCode/${code}`
				)
				setCodeObject(result.data)
			}
			getCode()
		} catch (error) {
			console.log(error)
		}
	}, [code])

	// Email associated with the code
	const email = codeObject.email

	// The type of code
	const type = codeObject.type

	return (
		<Container className="codes-container">
			{type === 'verification' ? (
				<Validated email={email} code={code} />
			) : type === 'forgotten' ? (
				<ResetForm email={email} code={code} />
			) : (
				<InvalidCode />
			)}
		</Container>
	)
}

export default CodesForm
