import React, { useState, useEffect } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import axios from 'axios'
import VenueDisplay from '../../components/venues/VenueDisplay'
import VenueStreams from '../../components/venues/VenueStreams'

/**
 * The page that will dispaly videos and venue information for a specific venue
 */

const VenuePage = ({ match }) => {
	const [venue, setVenue] = useState([])

	useEffect(() => {
		async function getVenue() {
			const result = await axios.get(`/api/venues/id/${match.params.id}`)

			setVenue(result.data)
		}
		getVenue()
	}, [match])

	return (
		<>
			<VenueDisplay venueInfo={venue} />
			<Breadcrumb>
				<Breadcrumb.Item href="/"> Home </Breadcrumb.Item>
				<Breadcrumb.Item href="/livestreams"> Venues </Breadcrumb.Item>
				<Breadcrumb.Item active> Livestreams </Breadcrumb.Item>
			</Breadcrumb>
			<VenueStreams venueInfo={venue} />
		</>
	)
}

export default VenuePage
