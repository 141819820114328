import React, { useState, useEffect, useRef, useContext } from 'react'
import { Container, Button, Breadcrumb } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import Hls from 'hls.js'
import ClipForm from '../../components/ClipForm'
import ToastNotifications from '../../components/ToastNotifications'
import { useToast } from '../../shared/context/toast-context'
import axios from 'axios'
import { AuthContext } from '../../shared/context/auth-context'
import skipBackwardsImage from '../../assets/vendorLogos/skip-backwards.png'
import skipForwardsImage from '../../assets/vendorLogos/skip-forwards.png'

const StreamPage = ({ match }) => {
	const MAX_CLIP_LENGTH = 300

	let camera
	let tableNumber
	let clipURL
	const [venue, setVenue] = useState({})
	const [played, setPlayed] = useState(0)
	const [isMakingClip, setIsMakingClip] = useState(false)
	const [, setClipLengthValid] = useState(true)
	const [createdClipUrl, setCreatedClipUrl] = useState(null)
	const [duration, setDuration] = useState(60)

	const { addToast } = useToast()

	/**
	 * Will display the stream player
	 */

	useEffect(() => {
		const getVenue = async () => {
			const { data } = await axios.get(
				`/api/venues/publicKey/${match.params.id}`
			)
			setVenue(data)
		}

		getVenue()
	}, [match])

	if (venue.camera) {
		camera = venue.camera.filter((cam) => {
			if (cam.publicKey.toLowerCase() === match.params.id.toLowerCase()) {
				return cam
			} else {
				return null
			}
		})
		tableNumber = camera[0].tableNumber // TODO: Unsafe access, handle null case
	}

	//const tooltipRef = useRef(null);
	// useEffect(() => {
	//   new Tooltip(tooltipRef.current, {
	//     title: "This is the local time for when the recording was taken",
	//     placement: "top",
	//     trigger: "hover",
	//   });
	// });

	/**
	 * Variables used to display a clock with the video player
	 * Clock functionality - Takes the current time of the video and adds it to the time the video was streamed at.
	 * 'today' should be grabbed from billycam API to be the time and date that the livestream was started.
	 * Will need to add an axios API call to get that information for the time.
	 */
	// const today = new Date("2021-06-23T21:06:00.000Z").valueOf();
	// const timePlayed = today + played * 1000;
	// const currentTime = new Date(timePlayed).toLocaleTimeString();

	// This function facilitates the creation of clips. Once the button to create a clip is clicked and the time entered is valid, it will send
	// a request to the backend and it will return a path to the file, then it will open another window, display the clip and it will be available to download.

	// Not working as of Aug 11. Issue with start time on BillyCam API
	const handleClip = async (evt) => {
		evt.preventDefault()
		setIsMakingClip(true)

		if (duration <= MAX_CLIP_LENGTH) {
			setClipLengthValid(true)
			await axios
				.post(
					`${process.env.REACT_APP_CONTROL_HOST}/stream/clip/${match.params.id}`,
					{
						startTime: played,
						length: duration,
					}
				)
				.then(function (res) {
					clipURL = res.data
					setCreatedClipUrl(clipURL)
					addToast('Your clip is now ready!')
					setIsMakingClip(false)
				})
				.catch(function () {
					setIsMakingClip(false)
					console.log('error creating clip')
				})
		} else {
			console.log('clip is too long!')
			setClipLengthValid(false)
			setIsMakingClip(false)
		}
	}

	// Uses useRef to seek on reactplayer
	const handleSkipFoward = () => {
		player.current.seekTo(played + 30)
	}

	const handleSkipBackward = () => {
		player.current.seekTo(played - 15)
	}

	const handleDurationChange = (clipLength) => {
		setDuration(clipLength)
	}

	const player = useRef(null)
	const auth = useContext(AuthContext)
	const streamUrl = `${process.env.REACT_APP_STREAM_HOST}/hls/${match.params.id}.m3u8`

	useEffect(() => {
		const loadVideo = async () => {
			const videoHtml = document.getElementById('LivestreamPlayer_HTML')
			if (Hls.isSupported()) {
				const hls = new Hls()
				hls.loadSource(streamUrl)
				hls.attachMedia(videoHtml)
			} else if (videoHtml.canPlayType('application/vnd.apple.mpegurl')) {
				videoHtml.src = streamUrl
			}
		}
		loadVideo()
	}, [streamUrl])

	return (
		<>
			<Breadcrumb>
				<Breadcrumb.Item href="/"> Home </Breadcrumb.Item>
				<Breadcrumb.Item href="/livestreams"> Venues </Breadcrumb.Item>
				<Breadcrumb.Item href={`/venuePage/${venue._id}`}>
					{' '}
					Livestreams{' '}
				</Breadcrumb.Item>
				<Breadcrumb.Item active> Watch Live </Breadcrumb.Item>
			</Breadcrumb>

			<Container>
				<ToastNotifications />
				{/* Player */}
				<ReactPlayer
					ref={player}
					url={`${process.env.REACT_APP_STREAM_HOST}/hls/${match.params.id}.m3u8`}
					width="100%"
					height="100%"
					controls={true}
					onProgress={(progress) => {
						setPlayed(progress.playedSeconds)
					}}
				/>
				<Button variant="outline-light" onClick={handleSkipBackward}>
					<img
						src={skipBackwardsImage}
						alt={'Skip backwards'}
						width="35"
					/>
				</Button>
				<Button variant="outline-light" onClick={handleSkipFoward}>
					<img
						src={skipForwardsImage}
						alt={'Skip forwards'}
						width="35"
					/>
				</Button>

				{/* <Card style={{ backgroundColor: '#00614a' }}>
					<video id="LivestreamPlayer_HTML" controls></video>
				</Card> */}

				{/* Below is for timestamp function that will not display correct time until client API is fixed */}
				{/* <p ref={tooltipRef}>Time of Current Match: {currentTime}</p> */}
				<h2>
					Live from: {venue.name} at table{' '}
					{!venue.camera ? '' : tableNumber}
				</h2>
				<h4>{venue.description}</h4>
				{/* Checks if the user is logged in before allowing the creation of clips. When the button is clicked it will changed into a loading state and cannot be clicked again */}
				{auth.isLoggedIn === true ? (
					<ClipForm
						isMakingClip={isMakingClip}
						handleClip={handleClip}
						duration={duration}
						handleDurationChange={handleDurationChange}
						maxClipLength={MAX_CLIP_LENGTH}
						createdClipUrl={createdClipUrl}
					/>
				) : (
					<Container className="d-flex flex-row-reverse">
						<Button href="/login"> Please login to clip </Button>
					</Container>
				)}
			</Container>
		</>
	)
}

export default StreamPage
