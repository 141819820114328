import React from 'react'
import { Button, Card } from 'react-bootstrap'
import axios from 'axios'

const Validated = (props) => {
	const email = props.email
	const code = props.code

	// Verifies the user associated with the email
	axios
		.post(`${process.env.REACT_APP_WEB_API_HOST}/user/verifyUser`, {
			email: email,
		})
		.then(async (res) => {
			try {
				// Deletes the verification code so it can't be used again
				await axios
					.post(
						`${process.env.REACT_APP_WEB_API_HOST}/codes/delete-code`,
						{
							code: code,
						}
					)
					.then((res) => {
						console.log(res.data)
					})
			} catch (error) {
				console.log(error)
			}
		})

	return (
		<Card className="verified-card">
			<Card.Body>
				<Card.Title>
					<h1>Account Verified</h1>
				</Card.Title>
				<Card.Text className="text-muted">For Email: {email}</Card.Text>
				<h3>
					Thank you for verifying your account!
					<br />
					You will now be able to clip any recordings or streams you
					want!
				</h3>
				<Button
					onClick={() => {
						window.location.replace('/login')
					}}
				>
					Back to Login
				</Button>
			</Card.Body>
		</Card>
	)
}

export default Validated
