import React, { useState } from 'react'
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/shared/Header'
import Footer from './components/shared/Footer'
import ToastNotifications from './components/ToastNotifications'
import Streams from './pages/streamlist/StreamList'
import StreamPage from './pages/streamPlayer/Stream'
import HomePage from './pages/home/home'
import AboutPage from './pages/about/about'
import NotFoundPage from './pages/notFound/notFound'
import LoginSignUp from './pages/loginsignup/LoginSignUp'
import Forget from './pages/forget/Forget'
import AccountSettings from './pages/account/AccountSettings'
import VenueSettings from './pages/venue/VenueSettings'
import Admin from './pages/admin/Admin'
import { useAuth } from './shared/hooks/auth-hook'
import { AuthContext } from './shared/context/auth-context'
import { ToastProvider } from './shared/context/toast-context'
import Contact from './pages/contact/contact'
import VenueList from './components/recordings/VenueList'
import VideoList from './components/recordings/VideoList'
import VenuePage from './pages/venueStreams/VenuePage'
import Codes from './pages/codes/codes'
import verify from './pages/verifyResend/verify'
import TermsAndConditions from './pages/documents/termsAndConditions'
import PrivacyPolicy from './pages/documents/privacyPolicy'
import PageContainer from './PageContainer'

var userDecoded = undefined

const App = () => {
	const { token, login, logout, userId, userRole, userEmail } = useAuth()

	if (Boolean(token)) {
		userDecoded = {
			id: userId,
			role: userRole,
			email: userEmail,
		}
	}

	const [loginEmail, setLoginEmail] = useState('')
	const [loginReCAPTCHA, setLoginReCAPTCHA] = useState(true)
	const [headerUpdate, setHeaderUpdate] = useState(false)

	return (
		<AuthContext.Provider
			value={{
				isLoggedIn: !!token,
				userId: userId,
				userRole: userRole,
				userEmail: userEmail,
				token: token,
				login: login,
				logout: logout,
			}}
		>
			<ToastProvider>
				<Router>
					<PageContainer>
						<Route
							component={() => (
								<Header
									update={headerUpdate}
									userInfo={userDecoded}
								/>
							)}
						/>
						<main className="py-3">
							<Container>
								<ToastNotifications />
								<Switch>
									<Route path="/livestreams" exact>
										<Streams user={userDecoded} />
									</Route>
									<Route
										path="/stream/:id"
										component={StreamPage}
									/>
									<Route
										path="/login/"
										component={() => (
											<LoginSignUp
												setLoginEmailHandler={
													setLoginEmail
												}
												loginEmail={loginEmail}
												setLoginReCAPTCHAHandler={
													setLoginReCAPTCHA
												}
												loginReCAPTCHA={loginReCAPTCHA}
											/>
										)}
										exact
									/>
									<Route
										path="/forgot/"
										component={Forget}
										exact
									/>
									<Route
										path="/accsetting/"
										component={() => (
											<AccountSettings
												setRefreshHandler={
													setHeaderUpdate
												}
												update={headerUpdate}
												userInfo={userDecoded}
											/>
										)}
										exact
									/>
									<Route
										path="/admin/"
										component={() => (
											<Admin userInfo={userDecoded} />
										)}
										exact
									/>
									<Route
										path="/recordings/"
										component={VenueList}
										exact
									/>
									<Route
										path="/"
										component={HomePage}
										exact
									/>
									<Route
										path="/home/"
										component={HomePage}
										exact
									/>
									<Route
										path="/about/"
										component={AboutPage}
										exact
									/>
									<Route
										path="/404/"
										component={NotFoundPage}
										exact
									/>
									<Route
										path="/contact/"
										component={Contact}
										exact
									/>
									<Route
										path="/videolist/:id"
										component={VideoList}
										exact
									/>
									<Route
										path="/venuePage/:id"
										component={VenuePage}
										exact
									/>
									<Route
										path="/login/:code"
										component={Codes}
										exact
									/>
									<Route
										path="/verify/"
										component={verify}
										exact
									/>
									<Route
										path="/venue-setting/"
										component={VenueSettings}
										exact
									/>
									<Route
										path="/termsandconditions"
										component={TermsAndConditions}
										exact
									/>
									<Route
										path="/privacypolicy"
										component={PrivacyPolicy}
										exact
									/>
									<Redirect to="/404" />
								</Switch>
							</Container>
						</main>
						<Footer />
					</PageContainer>
				</Router>
			</ToastProvider>
		</AuthContext.Provider>
	)
}

export default App
