import React from 'react'
import {
	Container,
	Button,
	Spinner,
	Form,
	Row,
	Col,
	Alert,
} from 'react-bootstrap'

const ClipForm = ({
	isMakingClip,
	handleClip,
	duration,
	handleDurationChange,
	maxClipLength,
	createdClipUrl,
}) => {
	const handlePreviewButtonClick = () => {
		const filename = createdClipUrl.substring(
			createdClipUrl.lastIndexOf('/') + 1
		)
		window.open(`/clip/${filename}`)
	}

	const handelDownloadButtonClick = () => {}

	const renderClipLengthWarning = () => {
		if (!isMakingClip && duration > maxClipLength) {
			return (
				<Row>
					<Col>
						<Alert variant={'warning'}>
							Clip length must be shorter than {maxClipLength}
						</Alert>
					</Col>
				</Row>
			)
		}
	}

	return (
		<Container className="d-flex flex-row-reverse">
			<Form onSubmit={handleClip}>
				<Row>
					<Col>
						<Form.Group className="mb-3" controlId="formClipLength">
							<Form.Label>Clip Length (seconds)</Form.Label>
							<Form.Control
								type="number"
								value={duration}
								onChange={(e) =>
									handleDurationChange(e.target.value)
								}
							/>
						</Form.Group>
					</Col>
				</Row>
				{renderClipLengthWarning()}
				<Row>
					<Col xs={isMakingClip ? 8 : 10}>
						<Button
							type="submit"
							data-toggle="tooltip"
							data-placement="right"
							title="This process can take up to a minute or more depending on the length of the clip"
						>
							Create Clip
						</Button>
					</Col>

					{isMakingClip ? (
						<Col xs={2}>
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner>
						</Col>
					) : (
						<></>
					)}
				</Row>
				{createdClipUrl ? (
					<Row>
						<Col>
							<Button onClick={handlePreviewButtonClick}>
								Preview
							</Button>
						</Col>
						<Col>
							<Button onClick={handelDownloadButtonClick}>
								Download
							</Button>
						</Col>
					</Row>
				) : (
					<></>
				)}
			</Form>
		</Container>
	)
}

export default ClipForm
