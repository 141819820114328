import React, { Component } from 'react'
import './AvatarMenu.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faChevronDown,
	faChevronUp,
	faStoreAlt,
	faStar,
	faCog,
} from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '../../shared/context/auth-context'
import { Button } from 'react-bootstrap'

//1: ordinary logged-in user
//2: paying user
//3: venue owner
//9: administrator
//10: super user/owner
const roles = {
	1: 'General user',
	2: 'Paying',
	3: 'Venue owner',
	9: 'Admin',
	10: 'Super',
}

const VenueSettingItem = (
	<li>
		<a className="user-menu-link" href="/venue-setting">
			<FontAwesomeIcon icon={faStoreAlt} />
			<div>Venue settings</div>
		</a>
	</li>
)

const UserAdminItem = (
	<li>
		<a className="user-menu-link" href="/admin">
			<FontAwesomeIcon icon={faStar} />
			<div>User settings</div>
		</a>
	</li>
)

const SuperUserSettingItem = (
	<li>
		<a className="user-menu-link" href="/#">
			<FontAwesomeIcon icon={faCog} />
			<div>Super settings</div>
		</a>
	</li>
)

class AvatarMenu extends Component {
	constructor(prop) {
		super(prop)
		this.state = {
			isMenuShow: true,
			isAccountMenuHidden: true,
		}

		document.getElementsByTagName('body')[0].onclick = () => {
			this.setState({
				isMenuShow: true,
				isAccountMenuHidden: true,
			})
		}
	}

	specialSettingsEnable =
		this.props.userinfov2.role === 3 ||
		this.props.userinfov2.role === 9 ||
		this.props.userinfov2.role === 10

	logOut = () => {
		this.context.logout()
	}

	toggleMenu = (e) => {
		e.stopPropagation()

		this.setState({
			isMenuShow: !this.state.isMenuShow,
			isAccountMenuHidden: !this.state.isAccountMenuHidden,
		})
	}

	render() {
		return (
			<div className="user-menu-wrap">
				<Button
					variant="text"
					className="icon-wrapper text-light"
					onClick={this.toggleMenu}
				>
					<p>
						Account{' '}
						{this.state.isAccountMenuHidden ? (
							<FontAwesomeIcon icon={faChevronDown} />
						) : (
							<FontAwesomeIcon icon={faChevronUp} />
						)}
					</p>
				</Button>
				<div
					className={
						this.state.isMenuShow
							? 'menu-containerX '
							: 'menu-containerY'
					}
				>
					<ul className="user-menu">
						<div className="profile-highlight">
							<div className="details">
								<div id="profile-name">
									<p>{this.props.userinfov2.email}</p>
								</div>
								<div id="profile-footer">
									Status: {roles[this.props.userinfov2.role]}
								</div>
							</div>
						</div>
						{/*  */}
						<div
							className={
								this.specialSettingsEnable
									? 'special_settings'
									: null
							}
						>
							{roles[this.props.userinfov2.role] === 'Super'
								? SuperUserSettingItem
								: null}
							{roles[this.props.userinfov2.role] === 'Venue owner'
								? VenueSettingItem
								: null}
							{roles[this.props.userinfov2.role] === 'Admin'
								? UserAdminItem
								: null}
						</div>

						<div className="footer">
							<li>
								<a
									className="user-menu-link"
									href="/accsetting"
								>
									Profile Settings
								</a>
							</li>
							<li>
								<a
									className="user-menu-link"
									href="/"
									onClick={this.logOut}
									style={{
										color: '#F44336',
										fontWeight: 'bolder',
									}}
								>
									Logout
								</a>
							</li>
						</div>
					</ul>
				</div>
			</div>
		)
	}
}

AvatarMenu.contextType = AuthContext
export default AvatarMenu
