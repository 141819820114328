import React, { useState, useEffect } from 'react'
import { Row, Col, Breadcrumb } from 'react-bootstrap'
import axios from 'axios'

import VideoItem from './VideoItem'
import VenueDisplay from '../venues/VenueDisplay'

const VideoList = ({ match }) => {
	const [videos, setVideos] = useState([])
	const [venues, setVenues] = useState([])
	let filteredVideos

	useEffect(() => {
		async function getData() {
			const venue = await axios.get(`/api/venues/id/${match.params.id}`)
			const video = await axios.get(
				`${process.env.REACT_APP_CONTROL_HOST}/stream/recording/list.json`
			)
			setVenues(venue.data)
			setVideos(video.data)
		}
		getData()
	}, [match])

	if (videos) {
		filteredVideos = videos.reverse().filter((filtered) => {
			if (filtered.venue === venues.venueNumber) {
				return filtered
			} else {
				return null
			}
		})
	}

	return (
		<>
			<VenueDisplay venueInfo={venues} />
			<h1>Videos</h1>
			<Breadcrumb>
				<Breadcrumb.Item href="/"> Home </Breadcrumb.Item>
				<Breadcrumb.Item href="/recordings">Venues</Breadcrumb.Item>
				<Breadcrumb.Item active>Videos</Breadcrumb.Item>
			</Breadcrumb>
			<Row>
				{filteredVideos.map((video, i) => (
					<Col sm={12} md={6} lg={4} xl={3} key={i}>
						<VideoItem key={video.id} video={video} />
					</Col>
				))}
			</Row>
			{filteredVideos.length === 0 ? (
				<p>This venue has no recordings</p>
			) : (
				''
			)}
		</>
	)
}

export default VideoList
