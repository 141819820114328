import React from 'react'
import { Button, Form } from 'react-bootstrap'
import ImageUpload from './ImageUpload'

//V1 Works
/**
 * general information component
 *
 * @version 1.0.0
 * @author Nemo(xwang59@academic.rrc.ca)
 */
const FormGeneralInfoV1 = (props) => {
	return (
		<Form className="try-form">
			<Form.Group className="mb-3">
				<Form.Label>Venue Profile:</Form.Label>
				<ImageUpload src={props.profile} venueId={props.venueId} />
				<Form.Label>Venue Name:</Form.Label>
				<Form.Control
					type="text"
					onDoubleClick={props.onVenueDClick}
					value={props.venue}
					onChange={props.onVenueChange}
					id="inputVenue"
				/>
				<Form.Label>Description:</Form.Label>
				<Form.Control
					as="textarea"
					rows={3}
					onDoubleClick={props.onDescDClick}
					value={props.desc}
					onChange={props.onDescChange}
					id="inputDescribe"
				/>

				<div className="btn-align-right">
					<Button
						variant="warning"
						type="submit"
						onClick={props.onVenueSave}
					>
						Save
					</Button>
				</div>
			</Form.Group>
			<Form.Group className="mb-3">
				<Form.Label>Province:</Form.Label>
				<Form.Control
					type="text"
					onDoubleClick={props.onProvinceDClick}
					value={props.province}
					onChange={props.onProvinceChange}
					id="inputProvince"
				/>

				<Form.Label>City:</Form.Label>
				<Form.Control
					type="text"
					onDoubleClick={props.onCityDClick}
					value={props.city}
					onChange={props.onCityChange}
					id="inputCity"
				/>

				<Form.Label>Address:</Form.Label>
				<Form.Control
					type="text"
					onDoubleClick={props.onAddressDClick}
					value={props.add}
					onChange={props.onAddChange}
					id="inputAddress"
				/>

				<Form.Label>Postal Code:</Form.Label>
				<Form.Control
					type="text"
					onDoubleClick={props.onPostDClick}
					value={props.post}
					onChange={props.onPostChange}
					id="inputPost"
				/>

				<div className="btn-align-right">
					<Button
						variant="warning"
						type="submit"
						onClick={props.onAddSave}
					>
						Save
					</Button>
				</div>
			</Form.Group>
		</Form>
	)
}

export default FormGeneralInfoV1
