import React from 'react'
import { Card } from 'react-bootstrap'
import Icon from '../../assets/BillycamIcon.png'

// This component takes in the map of videos from VideoList as a prop and displays them in cards
const VenueItem = ({ video }) => {
	let a = video.download_link
	let link = a.slice(41)

	return (
		<Card className="my-5 p-3">
			<Card.Header>
				<a href={`/watch/${link}`}>
					<Card.Title as="div">
						<strong>{video.name}</strong>
					</Card.Title>
				</a>
			</Card.Header>
			<a href={`/watch/${link}`}>
				<Card.Img src={Icon} />
			</a>
		</Card>
	)
}

export default VenueItem
