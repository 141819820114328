import React from 'react'
import './Forget.css'

import ForgotPasswordForm from '../../components/login/forgotPassword'

const Forget = () => {
	return (
		<div className="com-forget">
			<ForgotPasswordForm />
		</div>
	)
}

export default Forget
