import React, { useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import axios from 'axios'
import EmailSending from '../../classes/emailSending'
import CodeGenerator from '../../classes/codeGenerator'

const SendEmail = new EmailSending()
const VerifyCode = new CodeGenerator()

/**
 * Checks given email against regex to see if it's valid
 * @param {string} email Email to validate
 * @returns true if email is valid, false if email is not valid
 */
const emailValidate = (email) => {
	console.log(email)
	const reg =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return reg.test(String(email).toLowerCase())
}

const ResendVerification = () => {
	const [email, setEmail] = useState('')
	const [error, setError] = useState('')
	const [loginButtonShow, setLoginButtonShow] = useState(false)
	let isVerified

	/**
	 * Checks to see if a user is verified or not
	 * @returns returns the promise response of the axios post
	 */
	const lookupVerifiedUser = async () => {
		console.log('emailLookupStart')
		console.log(email)
		const promise = await axios
			.post(`${process.env.REACT_APP_WEB_API_HOST}/email/userVerified`, {
				email: email,
			})
			.then((result) => {
				console.log(result.data)
				isVerified = result.data
			})
			.catch((error) => {
				console.error(error)
				return Promise.reject(error)
			})

		console.log('emailLookupEnd')

		return promise
	}

	const onSubmit = (e) => {
		e.preventDefault()
		setLoginButtonShow(false)
		setError('')
		const valid = emailValidate(email)

		if (valid) {
			lookupVerifiedUser(email).then(() => {
				if (typeof isVerified !== 'undefined' && !isVerified) {
					const code = VerifyCode.getCode()
					SendEmail.verifyAccountEmail(email, code)
					window.location.replace('/login')
				} else if (typeof isVerified !== 'undefined' && isVerified) {
					console.log('User already Verified')
					setError('Email provided is already verified')
					setLoginButtonShow(true)
				} else {
					console.log("User Doesn't Exist")
					setError('Invalid Email Provided')
					setLoginButtonShow(true)
				}
			})
		} else {
			setError('Invalid Email Provided')
			setLoginButtonShow(true)
		}
	}

	return (
		<Card className="reset-card">
			<Card.Body>
				<Card.Title>
					<h1>Resend Verification</h1>
				</Card.Title>
				<Form onSubmit={onSubmit}>
					<Form.Text>
						Please Enter in your Email to send the verification to.
					</Form.Text>
					<Form.Group className="mb-3" controlId="resetPasswordForm">
						<Form.Label>Email</Form.Label>
						<Form.Control
							type="email"
							onChange={(e) => {
								setEmail(e.target.value)
							}}
							placeholder=""
						/>
						<Form.Text className="text-danger">{error}</Form.Text>
					</Form.Group>
					<Button type="submit" value="Submit">
						Resend
					</Button>
				</Form>
				<Button
					onClick={() => {
						window.location.replace('/login')
					}}
					hidden={!loginButtonShow}
				>
					Back to Login
				</Button>
			</Card.Body>
		</Card>
	)
}

export default ResendVerification
