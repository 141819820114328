import { useState, useCallback, useEffect } from 'react'

let logoutTimer

export const useAuth = () => {
	const [token, setToken] = useState(false)
	const [tokenExpirationDate, setTokenExpirationDate] = useState()
	const [userId, setUserId] = useState(false)

	const [userRole, setUserRole] = useState(false)
	const [userEmail, setUserEmail] = useState(false)

	const login = useCallback((uid, role, email, token, expirationDate) => {
		setToken(token)
		setUserRole(role)
		setUserEmail(email)
		setUserId(uid)
		const tokenExpirationDate =
			expirationDate ||
			new Date(new Date().getTime() + 1000 * 60 * 60 * 8)
		setTokenExpirationDate(tokenExpirationDate)
		localStorage.setItem(
			'BillyCamUserData',
			JSON.stringify({
				userId: uid,
				userRole: role,
				userEmail: email,
				token: token,
				expiration: tokenExpirationDate.toISOString(),
			})
		)
	}, [])

	const logout = useCallback(() => {
		setToken(null)
		setTokenExpirationDate(null)
		setUserId(null)
		setUserRole(null)
		setUserEmail(null)
		localStorage.removeItem('BillyCamUserData')
	}, [])

	useEffect(() => {
		if (token && tokenExpirationDate) {
			const remainingTime =
				tokenExpirationDate.getTime() - new Date().getTime()
			logoutTimer = setTimeout(logout, remainingTime)
		} else {
			clearTimeout(logoutTimer)
		}
	}, [token, logout, tokenExpirationDate])

	useEffect(() => {
		const storedData = JSON.parse(localStorage.getItem('BillyCamUserData'))
		if (
			storedData &&
			storedData.token &&
			new Date(storedData.expiration) > new Date()
		) {
			login(
				storedData.userId,
				storedData.userRole,
				storedData.userEmail,
				storedData.token,
				new Date(storedData.expiration)
			)
		}
	}, [login])

	return { token, login, logout, userId, userRole, userEmail }
}
