import React from 'react'
import { Container } from 'react-bootstrap'
import ResendVerification from '../../components/codes/resendVerification'

const Verify = () => {
	return (
		<Container>
			<ResendVerification />
		</Container>
	)
}

export default Verify
