import React from 'react'
import { CameraItem } from './CameraItem'

const cameraColors = [
	'Primary',
	'Secondary',
	'Success',
	'Danger',
	'Warning',
	'Info',
	'Dark',
	//
	'Crimson',
	'FireBrick',
	'DeepPink',
	'MediumVioletRed',
	'Tomato',
	'OrangeRed',
	'DarkOrange',
	'Gold',
	'DarkKhaki',
	'Violet',
	'Magenta',
	'BlueViolet',
	'DarkMagenta',
	'SlateBlue',
	'Lime',
	'LimeGreen',
	'SeaGreen',
	'YellowGreen',
	'LightSeaGreen',
	'DodgerBlue',
	'RoyalBlue',
	'SandyBrown',
	'Chocolate',
]

/**
 * all the cameras component
 *
 * @version 1.0.0
 * @author Nemo(xwang59@academic.rrc.ca)
 */
const CameraAll = (props) => {
	//  let colorIdx = Math.floor(Math.random() * cameraColors.length) + 0;

	return props.cameras.map((camera, index) => {
		return (
			<CameraItem
				key={index}
				ctable={camera.tableNumber}
				ckey={camera.publicKey}
				cid={camera.cameraID}
				_cid={camera._id}
				cdesc={camera.desc}
				color={cameraColors[index % cameraColors.length]}
				header={`Camera #${index + 1}`}
				onCameraItemClick={props.onFormCameraItemClick}
				onCameraItemDelClick={props.onFormCameraItemDelClick}
			/>
		)
	})
}

export default CameraAll
