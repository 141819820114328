import React, { Component } from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import Spinner from 'react-bootstrap/Spinner'
import {
	API_EmailValidate,
	API_PasswordValidate,
} from '../../api/API_EmailPass'
import { PORTS } from '../../portcfg/ServerPortsCfg'
import { Tabs, Tab } from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'
import CodeGenerator from '../../classes/codeGenerator'
import EmailSending from '../../classes/emailSending'
import { AuthContext } from '../../shared/context/auth-context'

import './LoginSignUp.css'

const SendEmail = new EmailSending()
const VerifyCode = new CodeGenerator()

const eye = <FontAwesomeIcon icon={faEye} size="2x" />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} size="2x" />

/**
 * FOR ISEESPORTS.COM
 * SITE KEY:   6LdxUDYbAAAAAM8HpNfBDxs9vVxXffDsrG4lBHvW
 * SECRET KEY:
 *
 * FOR LOCAL
 * SITE KEY:   6Lf-ZjYbAAAAAMZfKvr8Gvgu3guCQWluMYpsk8zH
 * SECRET KEY: 6Lf-ZjYbAAAAAG9QjHnSHcwsPy1Av4SQa7vNzBRA
 */

const ReCAPTCHA_Key = '6Lf-ZjYbAAAAAMZfKvr8Gvgu3guCQWluMYpsk8zH'

const recaptchaRef = React.createRef()

export var USER_PORT = PORTS.DFT.USR

class LoginSignUp extends Component {
	constructor(props) {
		super(props)

		this.state = {
			loginEmail: this.props.loginEmail,
			loginPassword: '',
			isLoginPasswordVisible: false,
			isLoginEmailError: false,
			isLoginPasswordError: false,
			isLoginRemember: false,
			isLoginRobot: this.robotEnum.ROBOT_INIT,
			loginServerMsg: '',

			signupEmail: '',
			signupPassword1st: '',
			signupPassword2nd: '',
			isPolicyAgreed: false,
			policyErrorMessage: '',
			isTermsAgreed: false,
			termsErrorMessage: '',
			isSignupEmailError: false,
			isSignupEmailExistError: false,
			signupPassErrorMsg: '',
			isSignupPasswordError: false,
			isSignupPasswordVisible: false,
			isSignupPasswordConformVisible: false,
			isSignupRobot: this.robotEnum.ROBOT_INIT,
			signupServerMsg: '',
			isSignupSuccessShow: false,
			redirect: '/',
		}
		this.refreshPageHandler = this.refreshPageHandler.bind(this)
	}

	passwordErrorEnum = {
		ERROR_NE: 1,
		ERROR_NULL: 2,
		ERROR_REG: 3,
		ERROR_NONE: 0,
	}

	robotEnum = {
		ROBOT_INIT: 1,
		ROBOT_OK: 2,
		ROBOT_EXP: 3,
	}

	errorMsg = (
		<>
			Password must include:
			<ul>
				<li>At least one number,</li>
				<li>At least one lowercase,</li>
				<li>At least one uppercase letter,</li>
				<li>At least one special character(!@#$%^&*) </li>
				<li>At least 6 characters</li>
			</ul>
		</>
	)

	eyeShow = (
		<i
			onClick={() =>
				this.setState({
					isLoginPasswordVisible: false,
				})
			}
		>
			{eye}
		</i>
	)

	eyeSlashShow = (
		<i
			onClick={() =>
				this.setState({
					isLoginPasswordVisible: true,
				})
			}
		>
			{eyeSlash}
		</i>
	)

	eyeShowSignup = (
		<i
			onClick={() =>
				this.setState({
					isSignupPasswordVisible: false,
				})
			}
		>
			{eye}
		</i>
	)

	eyeSlashShowSignup = (
		<i
			onClick={() =>
				this.setState({
					isSignupPasswordVisible: true,
				})
			}
		>
			{eyeSlash}
		</i>
	)

	eyeShowSignupConform = (
		<i
			onClick={() =>
				this.setState({
					isSignupPasswordConformVisible: false,
				})
			}
		>
			{eye}
		</i>
	)

	eyeSlashShowSignupConform = (
		<i
			onClick={() =>
				this.setState({
					isSignupPasswordConformVisible: true,
				})
			}
		>
			{eyeSlash}
		</i>
	)

	reCAPTCHAChangeLogin = (value) => {
		switch (this.state.isLoginRobot) {
			case this.robotEnum.ROBOT_INIT:
				if (value !== null) {
					this.setState({
						isLoginRobot: this.robotEnum.ROBOT_OK,
						loginServerMsg: '',
					})
				}
				break
			case this.robotEnum.ROBOT_OK:
				if (value === null) {
					this.setState({
						isLoginRobot: this.robotEnum.ROBOT_EXP,
						loginServerMsg: '',
					})
				}
				break
			case this.robotEnum.ROBOT_EXP:
				if (value !== null) {
					this.setState({
						isLoginRobot: this.robotEnum.ROBOT_OK,
						loginServerMsg: '',
					})
				}
				break
			default:
				console.log(value)
		}
	}

	reCAPTCHAChangeSignup = (value) => {
		this.setState({
			signupServerMsg: null,
		})

		switch (this.state.isSignupRobot) {
			case this.robotEnum.ROBOT_INIT:
				if (value !== null) {
					this.setState({
						isSignupRobot: this.robotEnum.ROBOT_OK,
					})
				}
				break
			case this.robotEnum.ROBOT_OK:
				if (value === null) {
					this.setState({
						isSignupRobot: this.robotEnum.ROBOT_EXP,
					})
				}
				break
			case this.robotEnum.ROBOT_EXP:
				if (value !== null) {
					this.setState({
						isSignupRobot: this.robotEnum.ROBOT_OK,
					})
				}
				break
			default:
				console.log(value)
		}
	}

	onSubmitReCAPTCHA = () => {
		const recaptchaValue = recaptchaRef.current.getValue()
		this.props.onSubmit(recaptchaValue)
	}

	loginSubmitClick = (e) => {
		e.preventDefault()

		axios
			.post(`${process.env.REACT_APP_WEB_API_HOST}/user/login/`, {
				email: this.state.loginEmail,
				password: this.state.loginPassword,
				remember_me: this.state.isLoginRemember,
				ReCAPTCHA: this.props.loginReCAPTCHA
					? this.state.isLoginRobot
					: this.robotEnum.ROBOT_OK,
			})
			.then((res) => {
				if (res.data.specialError) {
					this.setState({
						isLoginPasswordError: true,
						loginServerMsg: null,
					})
				} else {
					this.setState({
						loginServerMsg: res.data.result,
					})
				}

				if (res.data.status) {
					this.context.login(
						res.data.userId,
						res.data.userRole,
						res.data.userEmail,
						res.data.userToken,
						new Date(res.data.expiration)
					)
					window.location.href = '/'
				}
			})
			.catch((error) => {
				console.log('There was an error!', error)
			})
	}

	//
	componentDidMount() {
		axios
			.get(`${process.env.REACT_APP_WEB_API_HOST}/user/getport/`, {
				timeout: 1000,
			})
			.catch((error) => {
				USER_PORT = PORTS.BAC.USR
			})
	}

	//
	passwordValidate(pass1, pass2) {
		if (pass1 !== pass2) {
			return this.passwordErrorEnum.ERROR_NE
		}
		if (pass2 === '') {
			return this.passwordErrorEnum.ERROR_NULL
		}

		// at least one number, one lowercase, one uppercase letter and one special character
		// at least six characters
		const exist = API_PasswordValidate(pass2)
		if (!exist) {
			return this.passwordErrorEnum.ERROR_REG
		}
		return this.passwordErrorEnum.ERROR_NONE
	}

	rememberClick = () => {
		this.setState({
			isLoginRemember: !this.state.isLoginRemember,
		})
	}

	termsAgreeClick = () => {
		this.setState({
			isTermsAgreed: !this.state.isTermsAgreed,
		})
	}

	policyAgreeClick = () => {
		this.setState({
			isPolicyAgreed: !this.state.isPolicyAgreed,
		})
	}

	signupSubmitClick = (e) => {
		e.preventDefault()
		this.setState({
			isSignupEmailError: false,
			isSignupEmailExistError: false,
			isSignupPasswordError: false,
			// signupServerMsg: null,
		})

		const isEmailAvailable = API_EmailValidate(this.state.signupEmail)

		if (!isEmailAvailable) {
			this.setState({
				isSignupEmailError: true,
			})
			return
		} else {
			this.setState({
				isSignupEmailError: false,
			})
		}

		const error_ = this.passwordValidate(
			this.state.signupPassword1st,
			this.state.signupPassword2nd
		)

		switch (error_) {
			case this.passwordErrorEnum.ERROR_NE:
				this.setState({
					isSignupPasswordError: true,
					signupPassErrorMsg: 'Passwords do not match.',
				})
				return
			case this.passwordErrorEnum.ERROR_NULL:
				this.setState({
					isSignupPasswordError: true,
					signupPassErrorMsg: 'Password is required.',
				})
				return
			case this.passwordErrorEnum.ERROR_REG:
				this.setState({
					isSignupPasswordError: true,
					signupPassErrorMsg: this.errorMsg,
				})
				return
			default:
				this.setState({
					isSignupPasswordError: false,
					signupPassErrorMsg: '',
				})
				break
		}

		if (!this.state.isTermsAgreed) {
			this.setState({
				termsErrorMessage: 'Terms and Conditions must be agreed to.',
			})
			return
		} else {
			this.setState({
				termsErrorMessage: '',
			})
		}

		if (!this.state.isPolicyAgreed) {
			this.setState({
				policyErrorMessage: 'Privacy Policy must be agreed to.',
			})
			return
		} else {
			this.setState({
				policyErrorMessage: '',
			})
		}

		if (this.state.isSignupRobot !== this.robotEnum.ROBOT_OK) {
			this.setState({
				signupServerMsg: 'Please do the reCAPTCHA',
			})
			return
		}

		axios
			.post(`${process.env.REACT_APP_WEB_API_HOST}/user/register/`, {
				email: this.state.signupEmail,
				password: this.state.signupPassword2nd,
			})
			.then((res) => {
				if (res.data.status) {
					const rnSec2_5 = Math.floor(Math.random() * 5000) + 2000
					const rnSec1_3 = Math.floor(Math.random() * 3000) + 1000

					// Generates Code for verification
					const code = VerifyCode.getCode()

					this.setState({
						isSignupSuccessShow: true,
					})

					// Sends a verify account email
					SendEmail.verifyAccountEmail(this.state.signupEmail, code)

					setTimeout(() => {
						this.setState({
							signupServerMsg:
								res.data.result + ', Jumping to login...',
							isSignupSuccessShow: false,
						})

						setTimeout(() => {
							this.props.setLoginEmailHandler(
								this.state.signupEmail
							)
							this.props.setLoginReCAPTCHAHandler(false)
						}, rnSec1_3)
					}, rnSec2_5)
				} else {
					this.setState({
						isSignupEmailExistError: true,
					})
				}
			})
			.catch((error) => {
				console.log('There is an error!', error)
			})
	}

	//
	refreshPageHandler() {
		window.location.reload()
	}

	redirectBug = 0
	render() {
		this.redirectBug++
		if (this.redirectBug >= 1) {
			if (this.context.isLoggedIn) {
				return <Redirect to={this.state.redirect} />
			}
		}

		return (
			<div className="com-loginsignup">
				{/* <div className="loginsignup-frame"> */}
				<Tabs defaultActiveKey="Login" variant="tabs">
					<Tab eventKey="Login" title="Login">
						<br />
						<form>
							<div className="form-group">
								<label>
									Email Address:
									<input
										className="inputs"
										type="email"
										value={this.state.loginEmail}
										placeholder="Your Email"
										onFocus={() =>
											this.setState({
												isLoginEmailError: false,
												loginServerMsg: '',
											})
										}
										onChange={(e) =>
											this.setState({
												loginEmail: e.target.value,
											})
										}
									/>
								</label>
							</div>
							<div className="form-group">
								<label>
									Password:
									<input
										className="inputs password"
										type={
											this.state.isLoginPasswordVisible
												? 'text'
												: 'password'
										}
										style={{
											autoComplete: 'current-password',
										}}
										value={this.state.loginPassword}
										placeholder="Your Password"
										onFocus={() =>
											this.setState({
												isLoginPasswordError: false,
												loginServerMsg: '',
											})
										}
										onChange={(e) =>
											this.setState({
												loginPassword: e.target.value,
											})
										}
									/>
									{this.state.isLoginPasswordVisible
										? this.eyeShow
										: this.eyeSlashShow}
								</label>
							</div>
							<div className="form-group">
								<div className="custom-control custom-checkbox">
									<input
										type="checkbox"
										className="custom-control-input"
										id="rememberCheck"
										checked={this.state.isLoginRemember}
										onChange={this.rememberClick}
									/>
									<label
										className="custom-control-label"
										htmlFor="rememberCheck"
									>
										Remember me
									</label>
								</div>
							</div>{' '}
							<div className="error">
								{/* {this.state.isLoginPasswordError ? this.errorMsg : null} */}
								{this.state.isLoginPasswordError
									? 'Email or Password Entered is Invalid'
									: null}

								<p>{this.state.loginServerMsg}</p>
							</div>
							{!this.props.loginReCAPTCHA ? null : (
								<ReCAPTCHA
									sitekey={ReCAPTCHA_Key}
									hl="en"
									onChange={this.reCAPTCHAChangeLogin}
								/>
							)}
							<input
								id="form-LoginSubmit"
								type="submit"
								value="Submit"
								onClick={this.loginSubmitClick}
							/>
							<div className="link">
								<span>Forgot your </span>
								<NavLink activeClassName="active" to="/forgot/">
									password?
								</NavLink>
							</div>
						</form>
					</Tab>

					<Tab eventKey="Signup" title="Signup">
						<br />
						<form>
							<div className="form-group">
								<label>
									Email Address:
									<input
										className="inputs"
										type="email"
										value={this.state.signupEmail}
										placeholder="Your Email"
										onChange={(e) =>
											this.setState({
												signupEmail: e.target.value,
											})
										}
									/>
								</label>
							</div>
							<div className="form-group">
								<label>
									Password:
									<input
										className="inputs password"
										type={
											this.state.isSignupPasswordVisible
												? 'text'
												: 'password'
										}
										style={{
											autoComplete: 'new-password',
										}}
										value={this.state.signupPassword1st}
										placeholder="Your Password"
										onFocus={() =>
											this.setState({
												isSignupPasswordError: false,
											})
										}
										onChange={(e) =>
											this.setState({
												signupPassword1st:
													e.target.value,
											})
										}
									/>
									{this.state.isSignupPasswordVisible
										? this.eyeShowSignup
										: this.eyeSlashShowSignup}
								</label>
							</div>
							<div className="form-group">
								<label>
									Confirm Password:
									<input
										className="inputs password"
										type={
											this.state
												.isSignupPasswordConformVisible
												? 'text'
												: 'password'
										}
										value={this.state.password2nd}
										style={{
											autoComplete: 'new-password',
										}}
										placeholder="Confirm Your Password"
										onFocus={() =>
											this.setState({
												isSignupPasswordError: false,
											})
										}
										onChange={(e) =>
											this.setState({
												signupPassword2nd:
													e.target.value,
											})
										}
									/>
									{this.state.isSignupPasswordConformVisible
										? this.eyeShowSignupConform
										: this.eyeSlashShowSignupConform}
								</label>
							</div>
							<div className="form-group">
								<div className="custom-control custom-checkbox">
									<input
										type="checkbox"
										className="custom-control-input"
										id="termsAndConditions"
										checked={this.state.isTermsAgreed}
										onChange={this.termsAgreeClick}
									/>
									<label
										className="custom-control-label"
										htmlFor="termsAndConditions"
									>
										I have read and agree to the{' '}
										<a
											href="/termsandconditions"
											target="_blank"
										>
											Terms and Conditions
										</a>
									</label>
								</div>
								<div className="custom-control custom-checkbox">
									<input
										type="checkbox"
										className="custom-control-input"
										id="privacyPolicy"
										checked={this.state.isPolicyAgreed}
										onChange={this.policyAgreeClick}
									/>
									<label
										className="custom-control-label"
										htmlFor="privacyPolicy"
									>
										I have read and agree to the{' '}
										<a
											href="/privacypolicy"
											target="_blank"
										>
											Privacy Policy
										</a>
									</label>
								</div>
							</div>
							<div className="error">
								{this.state.isSignupEmailError ? (
									<p>Please enter a valid email.</p>
								) : null}
								{this.state.isSignupEmailExistError ? (
									<p>Email already exists.</p>
								) : null}
								{this.state.isSignupPasswordError ? (
									<p> {this.state.signupPassErrorMsg} </p>
								) : null}
								{this.state.policyErrorMessage}
								{this.state.termsErrorMessage}
								<p>{this.state.signupServerMsg}</p>
							</div>
							<div className="recaptcha">
								<ReCAPTCHA
									sitekey={ReCAPTCHA_Key}
									badge="inline"
									hl="en"
									type="audio"
									onChange={this.reCAPTCHAChangeSignup}
								/>
								<div className="spin">
									{this.state.isSignupSuccessShow ? (
										<Spinner
											animation="border"
											variant="warning"
										/>
									) : null}
								</div>
							</div>
							<input
								id="form-SignupSubmit"
								type="submit"
								value="Submit"
								onClick={this.signupSubmitClick}
							/>
						</form>
					</Tab>
				</Tabs>
			</div>
		)
	}
}

LoginSignUp.contextType = AuthContext
export default LoginSignUp
