import React from 'react'
import { Card } from 'react-bootstrap'
import Icon from '../../assets/BillycamIcon.png'

/**
 * A component to display stream link as cards
 */
const Stream = ({ stream, streamKey }) => {
	let streamingKey = streamKey.slice(0, -5)

	return (
		<Card className="my-5 p-3" key={stream.key}>
			<Card.Header>
				<a href={`/stream/${streamingKey}`}>
					<Card.Title as="div">
						<strong>{stream.name}</strong>
					</Card.Title>
				</a>
			</Card.Header>
			<a href={`/Stream/${streamingKey}`}>
				<Card.Img src={Icon} />
			</a>
		</Card>
	)
}

export default Stream
