import React from 'react'
import { Toast } from 'react-bootstrap'
import { useToast } from '../shared/context/toast-context'

const ToastNotifications = () => {
	const { toasts, removeToast } = useToast()
	const renderToasts = () => {
		if (toasts !== null && toasts !== undefined) {
			return toasts.map((toastItem, index) => {
				return (
					<Toast
						style={{
							position: 'absolute',
							top: 0,
							right: 0,
							zIndex: 1,
						}}
						onClose={() => removeToast(index)}
						key={index}
					>
						<Toast.Header>
							{/* <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" /> */}
							<strong className="mr-auto">Billycam</strong>
							<small>just now</small>
						</Toast.Header>
						<Toast.Body>{toastItem}</Toast.Body>
					</Toast>
				)
			})
		}
	}

	return (
		<div
			aria-live="polite"
			aria-atomic="true"
			style={{
				position: 'fixed',
				bottom: '5rem',
				left: '50%',
				minHeight: '100px',
				marginLeft: '-6.25%',
				minWidth: '12.5%',
				zIndex: 1,
			}}
		>
			{renderToasts()}
		</div>
	)
}

export default ToastNotifications
