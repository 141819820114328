import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import ImageFigure from '../../components/general/ImageFigure'
import './home.css'

const HomePage = () => {
	return (
		<Container fluid>
			<Row>
				<Col>
					<ImageFigure
						image="PlayCroppedRectangle.jpg"
						caption="Play"
					/>
				</Col>
				<Col>
					<ImageFigure
						image="Review3CroppedRectangle.png"
						caption="Review"
					/>
				</Col>
				<Col>
					<ImageFigure
						image="ShareCroppedRectangle.jpg"
						caption="Share"
					/>
				</Col>
			</Row>
			<Row>
				<Col className="LinkImage">
					<a href="/livestreams" className="justify-self-center">
						<ImageFigure
							image="Livestreams.jpg"
							caption="Livestreams"
						/>
					</a>
				</Col>
				<Col className="LinkImage">
					<a href="/recordings">
						<ImageFigure
							image="Recordings.jpg"
							caption="Recordings"
						/>
					</a>
				</Col>
			</Row>
		</Container>
	)
}

export default HomePage
