import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import EmailSending from '../../classes/emailSending'
import CodeGenerator from '../../classes/codeGenerator'

const SendEmail = new EmailSending()
const ForgotCode = new CodeGenerator()

/**
 * Checks given email against regex to see if it's valid
 * @param {string} email Email to validate
 * @returns true if email is valid, false if email is not valid
 */
const emailValidate = (email) => {
	console.log(email)
	const reg =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return reg.test(String(email).toLowerCase())
}

const ForgotPasswordForm = () => {
	/**
	 * Inital states creation
	 */
	let emailExists = false
	const [email, setEmail] = useState('')
	const [error, setError] = useState('')

	/**
	 * Looks up the email state in the database.
	 * @returns Promise with the value true or false if email exists or not.
	 */
	const emailLookup = async () => {
		console.log('emailLookupStart')
		console.log(email)
		const promise = await axios
			.post(`${process.env.REACT_APP_WEB_API_HOST}/email/userexist`, {
				email: email,
			})
			.then(async (result) => {
				console.log(result.data)
				emailExists = result.data
			})
			.catch((error) => {
				console.error(error)
				return Promise.reject(error)
			})

		console.log('emailLookupEnd')

		return promise
	}

	const submitClick = (e) => {
		e.preventDefault()
		e.stopPropagation()
		console.log('SubmitClick Start')
		const isEmailValid = emailValidate(email)

		setError('')

		if (!isEmailValid) {
			console.log('Email bad')
			setError('Please provide a valid email')
		} else {
			emailLookup().then(() => {
				if (!emailExists) {
					console.log("User doesn't Exist")
					window.location.replace('/login')
				} else {
					try {
						const code = ForgotCode.getCode()
						SendEmail.forgotPasswordEmail(email, code).then(() => {
							window.location.replace('/login')
						})
					} catch (error) {
						alert('Email Sending Error: ' + error)
					}
				}
			})
		}
	}

	return (
		<form className="forget-frame" onSubmit={submitClick}>
			<h2>Forgot your password?</h2>
			<h4>
				Please enter your email address. You will receive a link to
				create a new password via email.
			</h4>
			<label>
				Email Address:
				<input
					className="inputs"
					type="email"
					value={email}
					name="email"
					placeholder="Your Email"
					onChange={(e) => setEmail(e.target.value)}
				/>
				<p className="text-danger">{error}</p>
			</label>
			<div className="link">
				<Link to="/login/">Back to login</Link>
			</div>
			<input id="form-submit" type="submit" value="Submit" />
		</form>
	)
}

export default ForgotPasswordForm
