class CodeGenerator {
	//Generates 2 sets of random numbers and letters and combines them to create a code
	getCode() {
		return (
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15)
		)
	}
}

export default CodeGenerator
