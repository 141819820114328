import React, { useState, useContext, useEffect } from 'react'
import { Card, Row, Col, Spinner, Button } from 'react-bootstrap'
import { AuthContext } from '../../shared/context/auth-context'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

/**
 * Will dispaly all the venue inforamtion
 */

const VenueDisplay = ({ venueInfo }) => {
	const [isFavorite, setIsFavorite] = useState(false)
	const [isVenueHidden, setIsVenueHidden] = useState(true)
	const auth = useContext(AuthContext)
	let address =
		venueInfo.city + ' ' + venueInfo.province + ', ' + venueInfo.address

	//Will add the current venue to the logged in users favorites
	const addToFavorites = async () => {
		await axios.post(
			`${process.env.REACT_APP_WEB_API_HOST}/user/favorite/${auth.userId}`,
			{
				venueID: venueInfo._id,
				venueNumber: venueInfo.venueNumber,
				venueName: venueInfo.name,
				venueAddress: address,
			}
		)

		setIsFavorite(true)
	}

	//Will remove the current venue from the users favorites
	const removeFromFavorites = async () => {
		await axios.post(
			`${process.env.REACT_APP_WEB_API_HOST}/user/favorite/remove/${auth.userId}/${venueInfo._id}`
		)

		setIsFavorite(false)
	}

	useEffect(() => {
		if (auth.isLoggedIn && venueInfo._id) {
			async function getIsFavorite() {
				let response = await axios.get(
					`${process.env.REACT_APP_WEB_API_HOST}/user/favorites/${auth.userId}`
				)

				const favourites = await response.data

				const isVenueFavourited =
					favourites.filter((venue) => {
						return venue._id === venueInfo._id
					}).length > 0

				setIsFavorite(isVenueFavourited)
			}
			getIsFavorite()
		}
	}, [auth.isLoggedIn, venueInfo._id, auth.userId])

	return (
		<>
			<Card
				className="my-5 p-3"
				style={{ width: '20 rem' }}
				key={venueInfo._id}
			>
				<Card.Header>
					<Row>
						<Col>
							<Card.Title as="div">
								<strong>{venueInfo.name}</strong>
							</Card.Title>
						</Col>
						<Col md="auto">
							{!auth.isLoggedIn ? (
								<>
									<Button disabled={true}>
										Log in to add to Favorites{' '}
									</Button>
								</>
							) : !isFavorite ? (
								<>
									<Button onClick={addToFavorites}>
										Add to Favorites
									</Button>
								</>
							) : (
								<>
									<Button onClick={removeFromFavorites}>
										Remove from Favorites
									</Button>
								</>
							)}
						</Col>
						<Col md="auto">
							{isVenueHidden ? (
								<Button
									variant="link"
									onClick={() => {
										setIsVenueHidden(false)
									}}
								>
									<FontAwesomeIcon icon={faChevronDown} />
								</Button>
							) : (
								<Button
									variant="link"
									onClick={() => {
										setIsVenueHidden(true)
									}}
								>
									<FontAwesomeIcon icon={faChevronUp} />
								</Button>
							)}
						</Col>
					</Row>
				</Card.Header>
				<Row hidden={isVenueHidden}>
					<Col sm={12} md={6} lg={4} xl={3}>
						{!venueInfo.logo ? (
							''
						) : (
							<Card.Img
								src={`/backend/uploads/images/${venueInfo.logo}`}
							/>
						)}
					</Col>
					<Col sm={12} md={6} lg={4} xl={3}>
						{!venueInfo.socialLinks ? (
							<Spinner
								animation="border"
								variant="primary"
								role="status"
							/>
						) : (
							<>
								<p>Description: {venueInfo.description}</p>
								<p>Address: {address}</p>
								<p>{venueInfo.postalCode}</p>
								<p>
									Website:{' '}
									<a href={venueInfo.socialLinks.website}>
										{venueInfo.socialLinks.website}
									</a>
								</p>
								<p>
									Menu:{' '}
									<a href={venueInfo.socialLinks.menu}>
										{venueInfo.socialLinks.menu}
									</a>
								</p>
								<p>
									Google Map:{' '}
									<a href={venueInfo.socialLinks.googleMap}>
										{venueInfo.socialLinks.googleMap}
									</a>
								</p>
								<p>
									Apple Map:{' '}
									<a href={venueInfo.socialLinks.appleMap}>
										{venueInfo.socialLinks.appleMap}
									</a>
								</p>
								<p>
									Facebook:{' '}
									<a href={venueInfo.socialLinks.facebook}>
										{venueInfo.socialLinks.facebook}
									</a>
								</p>
								<p>
									Instagram:{' '}
									<a href={venueInfo.socialLinks.instagram}>
										{venueInfo.socialLinks.instagram}
									</a>
								</p>
								<p>
									Twitter:{' '}
									<a href={venueInfo.socialLinks.twitter}>
										{venueInfo.socialLinks.twitter}
									</a>
								</p>
							</>
						)}
					</Col>
					<Col sm={12} md={6} lg={4} xl={3}>
						{!venueInfo.socialLinks ? (
							''
						) : (
							<>
								<h5>Hours of Operation</h5>
								<p>
									Monday: {venueInfo.hoursOfOp.mon[0]} to{' '}
									{venueInfo.hoursOfOp.mon[1]}
								</p>
								<p>
									Monday: {venueInfo.hoursOfOp.tue[0]} to{' '}
									{venueInfo.hoursOfOp.tue[1]}
								</p>
								<p>
									Monday: {venueInfo.hoursOfOp.wed[0]} to{' '}
									{venueInfo.hoursOfOp.wed[1]}
								</p>
								<p>
									Monday: {venueInfo.hoursOfOp.thu[0]} to{' '}
									{venueInfo.hoursOfOp.thu[1]}
								</p>
								<p>
									Monday: {venueInfo.hoursOfOp.fri[0]} to{' '}
									{venueInfo.hoursOfOp.fri[1]}
								</p>
								<p>
									Monday: {venueInfo.hoursOfOp.sat[0]} to{' '}
									{venueInfo.hoursOfOp.sat[1]}
								</p>
								<p>
									Monday: {venueInfo.hoursOfOp.sun[0]} to{' '}
									{venueInfo.hoursOfOp.sun[1]}
								</p>
							</>
						)}
					</Col>
				</Row>
			</Card>
		</>
	)
}

export default VenueDisplay
