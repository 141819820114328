import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { Form, Button, Table } from 'react-bootstrap'
import { AuthContext } from '../../shared/context/auth-context'
import '@fortawesome/fontawesome-free/css/all.css'
import './Admin.css'

const roles = {
	1: 'General user',
	2: 'Paying',
	3: 'Venue owner',
	9: 'Admin',
	10: 'Super',
}

const __ROLES = {
	GeneralUser: 1,
	Paying: 2,
	VenueOwner: 3,
	Admin: 9,
	Super: 10,
}

/**
 * Administrator settings page.
 *
 * @version 1.0.0
 * @author Nemo(xwang59@academic.rrc.ca)
 */
class Admin extends Component {
	constructor(prop) {
		super(prop)
		this.state = {
			// pagenation for next version
			currentPage: 0,
			redirect: '/login/',
			redirectHome: '/',
			users: [],
			isLoading: false,
		}
		this.saveHandle = this.saveHandle.bind(this)
	}

	/* save user role as general user or venue owner
      1: general user
      3: venue owner
  */
	saveHandle = (e) => {
		// save current user role
		axios
			.post(
				`${process.env.REACT_APP_WEB_API_HOST}/user/update-role-v2/`,
				{
					id: e.target.id,
					role: e.target.value,
				}
			)
			.then((res) => {
				if (res.data.status) {
					// get updated user roles list
					this.componentDidUpdate__()
				}
			})
			.catch((error) => {
				console.log('There was an error!', error)
			})
	}

	/* save user role as general user or venue owner(and venue number if it's venue owner)
      1: general user
      3: venue owner
  */
	__saveHandle = (data) => {
		const { _id, role, venueNumCopy } = data
		axios
			.post(
				`${process.env.REACT_APP_WEB_API_HOST}/user/update-role-v2/`,
				{
					id: _id,
					role: role,
					venueNumCopy: venueNumCopy,
				}
			)
			.then((res) => {
				if (res.data.status) {
					// get updated user roles list
					this.componentDidUpdate__()
				}
			})
			.catch((error) => {
				console.log('There was an error!', error)
			})
	}

	// venue number editting
	onVenueNumChange = (e) => {
		this.setState({ users: this.state.users })
	}

	// user list
	userTable = (data) => {
		const VenueOwnerStyle = {
			color: 'red',
			fontWeight: 'bold',
		}
		const rows = data.map((user, i) => (
			<tr key={user._id}>
				<td className="td-first">{i + 1}</td>
				<td
					style={
						roles[user.role] === 'Venue owner'
							? VenueOwnerStyle
							: {}
					}
				>
					{user.email}
				</td>
				<td>
					<Form.Group controlId="formBasicSelect">
						<Form.Control
							as="select"
							value={user.role}
							onChange={(e) => {
								let items = [...this.state.users]
								let item = { ...items[i] }

								if (item.role === 3 && e.target.value === 1) {
									//venue owner -> general
									item.venueNumCopy = 'Null'
								} else if (
									item.role === 1 &&
									e.target.value === 3
								) {
									item.venueNumCopy = -1
								}
								item.role = e.target.value
								items[i] = item
								this.setState({ users: items })
							}}
						>
							<option value="1">General user</option>
							<option value="3">Venue owner</option>
						</Form.Control>
					</Form.Group>
				</td>
				<td>
					<Form.Control
						className="inputVenueNumber"
						type="text"
						readOnly={user.role === 1 ? true : false}
						value={
							user.venueNumCopy == null
								? 'Null'
								: user.venueNumCopy
						}
						onChange={(e) => {
							let items = [...this.state.users]
							let item = { ...items[i] }
							item.venueNumCopy = e.target.value
							items[i] = item
							this.setState({ users: items })
						}}
					/>
				</td>
				<td>
					{' '}
					<Button
						variant="warning"
						id={user._id}
						value={user.role}
						onClick={() => this.__saveHandle(user)}
					>
						Submit
					</Button>{' '}
				</td>
			</tr>
		))

		return (
			<div>
				<Table responsive="lg" striped bordered hover>
					<thead>
						<tr>
							<th className="td-first">#</th>
							<th>User</th>
							<th>Status</th>
							<th>Venue #</th>
							<th>Update</th>
						</tr>
					</thead>
					<tbody>{rows}</tbody>
				</Table>
			</div>
		)
	}

	// get updated venue number
	componentDidUpdate__() {
		axios
			.get(`${process.env.REACT_APP_WEB_API_HOST}/user/list-v2/`, {
				params: {
					email: this.context.userEmail,
				},
			})
			.then((res) => {
				let users_ = res.data
				users_.forEach((user) => {
					if (user.role === 3) {
						axios
							.get(
								`${process.env.REACT_APP_WEB_API_HOST}/venues-padding/venue-num-v2/${user.venueId_[0]}`
							)
							.then((res) => {
								user.venueNumRef = res.data.venueNum
							})
							.catch((error) => {
								console.log('There is an error!', error)
							})
					}
				})

				this.setState({
					users: users_,
				})
			})
			.catch((error) => {
				console.log('There was an error!', error)
			})
	}

	//
	componentDidMount() {
		if (this.context.isLoggedIn) {
			axios
				.get(`${process.env.REACT_APP_WEB_API_HOST}/user/list-v2/`, {
					params: {
						email: this.context.userEmail,
					},
				})
				.then((res) => {
					this.setState({
						users: res.data,
					})
				})
				.catch((error) => {
					console.log('There was an error!', error)
				})
		} else {
			this.setState({
				redirect: this.state.redirect,
			})
		}
	}

	redirectBug = 0
	render() {
		this.redirectBug++
		if (this.redirectBug >= 2) {
			if (!this.context.isLoggedIn) {
				return <Redirect to={this.state.redirect} />
			}
			if (
				this.context.isLoggedIn &&
				this.context.userRole !== __ROLES.Admin
			) {
				return <Redirect to={this.state.redirectHome} />
			}
		}

		return (
			<div className="com-admin">
				<div className="div-block div-block-ps">
					<h3>User admin</h3>
				</div>
				<div className="div-block div-block__">
					{this.state.users
						? this.userTable(this.state.users)
						: 'No users.'}
				</div>
			</div>
		)
	}
}

Admin.contextType = AuthContext
export default Admin
